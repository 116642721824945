const translations = {
    en: {
        translation: {
            "Yes": "Yes",
            "No": "No",
            "Welcome to Neurowide Studio": "Welcome to Neurowide Studio",
            "Home": "Home",
            "My projects": "My projects",
            "Projects": "Projects",
            "My lexicons": "My lexicons",
            "Team management": "Team management",
            "Team Management": "Team Management",
            "Team": "Team",
            "credits available": "credits available",
            "Last 5 projects": "Last 5 projects",
            "Create project": "Create project",
            "Create": "Create",
            "Shared projects": "Shared projects",
            "Waste bin": "Waste bin",
            "Waste Bin": "Waste Bin",
            "Lexicon": "Lexicon",
            "Select lexicon": "Select lexicon",
            "Lexicon name": "Lexicon name",
            "Cancel": "Cancel",
            "Add": "Add",
            "Select": "Select",
            "Lexicons management": "Lexicons management",
            "Lexicons Management": "Lexicons Management",
            "Add a member": "Add a member",
            "Team members": "Team members",
            "creators": "creators",
            "Profile": "Profile",
            "Personal information": "Personal information",
            "Project name": "Project name",
            "CHOICE OF LEXICONS": "CHOICE OF LEXICONS",
            "Select an additionnal lexicon for your project (optional).": "Select an additionnal lexicon for your project (optional).",
            "Transcribe": "Transcribe",
            "PROJECT LEXICONS": "PROJECT LEXICONS",
            "Team Lexicon": "Team Lexicon",
            "Team lexicon": "Team lexicon",
            "Settings": "Settings",
            "LICENCES": "LICENCES",
            "LICENCE": "LICENCE",
            "Licence name": "Licence name",
            "Number of creators": "Number of creators",
            "Administrator": "Administrator",
            "CHARACTERISTICS": "CHARACTERISTICS",
            "Credits": "Credits",
            "Number of transcriptions allowed": "Number of transcriptions allowed",
            "Number of translations allowed": "Number of translations allowed",
            "Max audio / video duration": "Max audio / video duration",
            "PERIOD VALIDITY": "PERIOD VALIDITY",
            "Period": "Period",
            "Added": "Added",
            "Start": "Start",
            "End": "End",
            "Delete": "Delete",
            "Add a word": "Add a word",
            "Project information": "Project information",
            "French": "French",
            "English": "English",
            "Required fields": "Required fields",
            "Finalized": "Finalized",
            "Initialized": "Initialized",
            "Deleted": "Deleted",
            "Private": "Private",
            "Update": "Update",
            "Translation": "Translation",
            "New project": "New project",
            "Status": "Status",
            "Source language": "Source language",
            "Upload your video or audio file": "Upload your video or audio file",
            "Max size 500 MB": "Max size 500 MB",
            "Lexicons": "Lexicons",
            "Add lexicon": "Add lexicon",
            "Select a lexicon": "Select a lexicon",
            "Translate": "Translate",
            "Burn subtitles": "Burn subtitles",
            "Finalize": "Finalize",
            "Export": "Export",
            "Restore": "Restore",
            "Do you want to confirm this action?": "Do you want to confirm this action?",
            "Forgot password?": "Forgot password?",
            "Forgot password": "Forgot password",
            "Get my reset code": "Get my reset code",
            "New password": "New password",
            "Temporary password": "Temporary password",
            "Send": "Send",
            "Validation code": "Validation code",
            "Log In": "Log In",
            "Password": "Password",
            "Log In to Neurowide.studio": "Log In to Neurowide.studio",
            "Recycle bin": "Recycle bin",
            "Media being uploaded": "Media being uploaded",
            "Media being transcribed": "Media being transcribed",
            "Media being translated": "Media being translated",
            "Project being render": "Project being render",
            "Language": "Language",
            "Incorrect email or password": "Incorrect email or password",
            "Firstname": "Firstname",
            "Lastname": "Lastname",
            "Your password must contain between 8 and 15 characters including at least one number, one capital letter and a special character.": "Your password must contain between 8 and 15 characters including at least one number, one capital letter and a special character.",
            "Exported" : "Exported",
            "Preview": "Preview",
            "Hello, What will you be working on today?": "Hello, What will you be working on today?",
            "Pick up where you left off.": "Pick up where you left off.",
            "Contribute to your team's projects.": "Contribute to your team's projects.",
            "Restore your deleted projects.": "Restore your deleted projects.",
            "Enrich your team's lexical field by adding your own lexicons.": "Enrich your team's lexical field by adding your own lexicons.",
            "Manage your team.": "Manage your team.",
            "Manage client licences.": "Manage client licences.",
            "Follow the workflow steps and export your work.": "Follow the workflow steps and export your work.",
            "Find your personal details here.": "Find your personal details here.",
            "Ex. Project 01": "Ex. Project 01",
            "Your temporary password has been reset.": "Your temporary password has been reset.",
            "Save subtitles": "Save subtitles",
            "Shared" : "Shared",
            "Not enough credit. Please liaise with the Neurowide sales department.": "Not enough credit. Please liaise with the Neurowide sales department.",
            "You can't add a new creator to your license. Please liaise with the Neurowide sales department.": "You can't add a new creator to your license. Please liaise with the Neurowide sales department.",
            "An error has occurred": "An error has occurred",
            "Upload video or audio": "Upload video or audio",
            "Media duration exceeds allowed limit!": "Media duration exceeds allowed limit!",
            "Max duration ": "Max duration ",
            "Subtitles saved!": "Subtitles saved!",
            "Project deleted": "Project deleted",
            "Project restaured": "Project restaured",
            "This user is already part of a team!": "This user is already part of a team!",
            "The maximum number of transcriptions has been reached or exceeded!": "The maximum number of transcriptions has been reached or exceeded!",
            "The maximum number of translation(s) has been reached or exceeded!": "The maximum number of translation(s) has been reached or exceeded!",
            "Lexicon deleted": "Lexicon deleted",
            "Impossible to delete the lexicon because of its use in a project!": "Impossible to delete the lexicon because of its use in a project!",
            "The changes have been applied!": "The changes have been applied!",
            "Neurowide.studio requires a larger screen, please use a desktop browser.": "Neurowide.studio requires a larger screen, please use a desktop browser.",
            "Upload completed!": "Upload completed!",
            "Your project has been exported!": "Your project has been exported!",
            "Your project will no longer be editable. Would you like to continue?": "Your project will no longer be editable. Would you like to continue?",
            "User not recognized by Neurowide-Studio.": "User not recognized by Neurowide-Studio.",
            LOADING: "Loading...",
            SUB_TEXT: "Subtitle Text",
            OPEN_VIDEO: "Open Video",
            OPEN_SUB: "Open Subtitle",
            EXPORT_ASS: "Export ASS",
            EXPORT_SRT: "Export SRT",
            EXPORT_VTT: "Export VTT",
            EXPORT_VIDEO: "Export Video",
            TRANSLATE: "Translation",
            TRANSLATING: "Translation...",
            TRANSLAT_SUCCESS: "Translation success",
            DECODE_START: "Start decoding audio",
            DECODE_SUCCESS: "Decoding audio success",
            DECODE_ERROR: "Decoding audio failed",
            VIDEO_EXT_ERR: "Unable to open this video format",
            SUB_EXT_ERR: "Unable to open this subtitle format",
            MOBILE_TIP: "Please use computer access",
            HOTKEY_01: "Space: Play / Pause",
            HOTKEY_02: "Ctrl + Z: Undo",
            CLEAR: "Clear",
            UNDO: "Undo",
            DELETE: "Delete Subtitle",
            MERGE: "Merge Next",
            SPLIT: "Split Subtitle",
            LOADING_FFMPEG: "Loading dependency...",
            LOADING_FONT: "Loading font...",
            LOADING_VIDEO: "Loading video...",
            LOADING_SUB: "Loading subtitle...",
            CLEAR_TIP: "Confirm that all data is cleared",
            BURN_ERROR: "Burn subtitles failed",
            BURN_START: "Start burn subtitles",
            BURN_SUCCESS: "Burn subtitles success",
            OPEN_VIDEO_ERROR: "Please open the video first",
        }
    },
    fr: {
        translation: {
            "Yes": "Oui",
            "No": "Non",
            "Welcome to Neurowide Studio": "Bienvenue sur Neurowide Studio",
            "Home": "Accueil",
            "My projects": "Mes projets",
            "Projects": "Projets",
            "My lexicons": "Mes lexiques",
            "Team management": "Gestion d’équipe",
            "Team Management": "Gestion d’équipe",
            "Team": "Équipe",
            "credits available": "crédits disponibles",
            "Last 5 projects": "5 derniers projets",
            "Create project": "Créer un projet",
            "Create": "Créer",
            "Shared projects": "Projets partagés",
            "Waste bin": "Corbeille",
            "Waste Bin": "Corbeille",
            "Lexicon": "Lexique",
            "Select lexicon": "Sélectionner un lexique",
            "Lexicon name": "Nom du lexique",
            "Cancel": "Annuler",
            "Add": "Ajouter",
            "Select": "Sélectionner",
            "Lexicons management": "Gestion des lexiques",
            "Lexicons Management": "Gestion des lexiques",
            "Add a member": "Ajout d'un membre",
            "Team members": "Membres de l'équipe",
            "creators": "créateurs",
            "Profile": "Profil",
            "Personal information": "Informations personnelles",
            "Project name": "Nom du projet",
            "CHOICE OF LEXICONS": "CHOIX DES LEXIQUES",
            "Select an additionnal lexicon for your project (optional).": "Sélectionnez un lexique additionnel pour votre projet (facultatif).",
            "Transcribe": "Transcrire",
            "YOUR LEXICONS": "LEXIQUES DU PROJET",
            "Team Lexicon": "Lexique de l'équipe",
            "Team lexicon": "Lexique de l’équipe",
            "Settings": "Caractéristiques",
            "LICENCES": "LICENCES",
            "LICENCE": "LICENCE",
            "Licence name": "Nom de la licence",
            "Number of creators": "Nombre de créateurs",
            "Administrator": "Administrateur",
            "CHARACTERISTICS": "CARACTÉRISTIQUES",
            "Credits": "Nombre de crédits",
            "Number of transcriptions allowed": "Nombre de transcriptions autorisées",
            "Number of translations allowed": "Nombre de traductions autorisées",
            "Max audio / video duration": "Durée maximum audio / vidéo",
            "PERIOD VALIDITY": "VALIDITÉ",
            "Period": "Période",
            "Added": "Ajouter",
            "Start": "Début",
            "End": "Fin",
            "Delete": "Supprimer",
            "Add a word": "Ajouter un mot",
            "Project information": "Détails du projet",
            "French": "Français",
            "English": "Anglais",
            "Required fields": "Champs obligatoires",
            "Update": "Modifier",
            "Translation": "Traduction",
            "Finalized": "Finalisé",
            "Initialized": "Initialisé",
            "Deleted": "Supprimé",
            "Private": "Privé",
            "New project": "Nouveau projet",
            "Status": "Statut",
            "Source language": "Langue source",
            "Upload your video or audio file": "Téléverser une vidéo ou un audio",
            "Max size 500 MB": "Taille max 500 Mo",
            "Lexicons": "Lexiques",
            "Select a lexicon": "Sélectionnez un lexique",
            "Add lexicon": "Créer un lexique",
            "Translate": "Traduire",
            "Finalize": "Finaliser",
            "Export": "Exporter",
            "Restore": "Restorer",
            "Do you want to confirm this action?": "Voulez-vous confirmer cette action ?",
            "Forgot password": "Mot de passe oublié",
            "Forgot password?": "Mot de passe oublié ?",
            "Get my reset code": "Récupérer mon code de réinitialisation",
            "New password": "Nouveau mot de passe",
            "Temporary password": "Mot de passe temporaire",
            "Send": "Envoyer",
            "Validation code": "Code de validation",
            "Log In": "Se connecter",
            "Password": "Mot de passe",
            "Log In to Neurowide.studio": "Connectez-vous à Neurowide.studio",
            "Recycle bin": "Corbeille",
            "Media being uploaded": "Média en cours de téléversement",
            "Media being transcribed": "Média en cours de transcription",
            "Media being translated": "Média en cours de traduction",
            "Project being render": "Projet en cours de finalisation",
            "Language": "Langue",
            "Incorrect email or password": "Email ou mot de passe incorrect",
            "Firstname": "Prénom",
            "Lastname": "Nom",
            "Your password must contain between 8 and 15 characters including at least one number, one capital letter and a special character.": "Votre mot de passe doit contenir entre 8 et 15 caractères dont au moins un chiffre, une lettre majuscule et un caractère spécial.",
            "Exported" : "Exporté",
            "Preview": "Prévisualisation",
            "Hello, what will you be working on today?": "Bonjour, sur quoi allez-vous travailler aujourd'hui ?",
            "Pick up where you left off.": "Reprenez votre travail là où vous en étiez.",
            "Contribute to your team's projects.": "Contribuez aux projets de votre équipe.",
            "Restore your deleted projects.": "Restaurez vos projets supprimés.",
            "Enrich your team's lexical field by adding your own lexicons.": "Enrichissez le champ lexical de votre équipe en ajoutant vos lexiques.",
            "Manage your team.": "Administrez votre équipe.",
            "Manage client licences.": "Administrez les licences clients.",
            "Follow the workflow steps and export your work.": "Suivez les étapes du workflow et exportez votre travail.",
            "Find your personal details here.": "Retrouvez ici vos informations personnelles.",
            "PROJECT LEXICONS": "LEXIQUES DU PROJET",
            "Ex. Project 01": "Ex. Projet 01",
            "Your temporary password has been reset.": "Votre mot de passe temporaire a été réinitialisé.",
            "Save subtitles": "Enregistrer les sous-titres",
            "Shared" : "Partagé",
            "Not enough credit. Please liaise with the Neurowide sales department.": "Crédit insuffisant. Veuillez vous rapprocher de votre contact commercial chez Neurowide.",
            "You can't add a new creator to your license. Please liaise with the Neurowide sales department.": "Vous ne pouvez plus ajouter de créateur sur votre licence. Veuillez vous rapprocher de votre contact commercial chez Neurowide.",
            "An error has occurred": "Une erreur est survenue",
            "Upload video or audio": "Téléverser une vidéo ou un audio",
            "Media duration exceeds allowed limit!": "La durée du média dépasse la limite autorisée !",
            "Max duration ": "Durée max ",
            "Subtitles saved!": "Sous-titres sauvegardés !",
            "Project deleted": "Projet supprimé",
            "Project restaured": "Projet restauré",
            "This user is already part of a team!": "Cet utilisateur se trouve déjà dans une équipe !",
            "The maximum number of transcriptions has been reached or exceeded!": "Le nombre maximum de transcriptions a été atteint ou dépassé !",
            "The maximum number of translation(s) has been reached or exceeded!": "Le nombre maximum de traduction(s) a été atteint ou dépassé !",
            "Lexicon deleted": "Lexique supprimé",
            "Impossible to delete the lexicon because of its use in a project!": "Impossible de supprimer le lexique car il est utilisé dans un projet !",
            "The changes have been applied!": "Les modifications ont été appliquées !",
            "Neurowide.studio requires a larger screen, please use a desktop browser.": "Neurowide.studio nécessite un écran plus large, veuillez utiliser un navigateur de bureau.",
            "Upload completed!": "Téléversement terminé !",
            "Your project has been exported!": "Votre projet a été exporté !",
            "Your project will no longer be editable. Would you like to continue?": "Votre projet ne sera plus modifiable. Voulez-vous continuer ?",
            "User not recognized by Neurowide-Studio.": "Utilisateur non reconnu par Neurowide-Studio.",
        }
    }
}

export default translations