import {useState, useEffect} from "react"
import {useTranslation} from "react-i18next"
import {TagsInput} from "react-tag-input-component"
import axios from "axios"
import {API_LEXICONS} from "../../constants/apiUrls"
import {connect} from "react-redux"
import {sessionSelector} from "../../redux/selectors/sessionSelector"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import arrowDownIcon from "../../assets/icon/arrow-down.svg"
import LoadingComponent from "../../components/loading-component/LoadingComponent"

function LexiconAccordion({sessionState, lexiconName, lexiconItems, lexiconID, isDisable}) {
    const {t} = useTranslation()
    const [display, isDisplay] = useState()
    const [selected, setSelected] = useState(lexiconItems)

    const handleRequest = (data) => {
        setSelected(data)
        currentAuthSession().then((res) => {
            REFRESH_COGNITO_TOKEN(res.idToken.toString(), res.idToken.payload.id_user, res.idToken.payload.id_team ? res.idToken.payload.id_team : undefined, res.idToken.payload.role)
        })
        const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
        axios.put(`${API_LEXICONS}/${lexiconID}/items`, data, headersRequest)
        .catch((err) => {
            console.log("error :", err)
        })
    }

    const handleClick = () => {
        isDisplay(!display)
    }

    useEffect(() => {
        setSelected(lexiconItems ? lexiconItems : [])
    }, [lexiconItems])

    if (!lexiconID || !lexiconName || !lexiconItems) return <LoadingComponent/>
    return (
        <>
        <div className={`lexicon-accordion ${isDisable && "team-lexicon-accordion"}`}>
            <div className="header" onClick={handleClick}>
                <h3>{lexiconName}</h3>
                <img src={arrowDownIcon} alt=""/>
            </div>
            <div className="content" style={{display: `${display ? "flex" : ""}`}}>
                <TagsInput
                    disabled={isDisable && true}
                    value={selected}
                    onChange={handleRequest}
                    name="word"
                    placeHolder={!isDisable && t("Add a word")}
                />
            </div>
        </div>
        </>
    )
}

export default connect(sessionSelector)(LexiconAccordion)