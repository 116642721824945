import {useState, useCallback, useEffect, useRef} from "react"
import {useTranslation} from "react-i18next"
import {Table} from "react-virtualized"
import unescape from "lodash/unescape"
import debounce from "lodash/debounce"
import DT from 'duration-time-conversion';

import plusIcon from "../../../assets/icon/plus.svg"
import trashIcon from "../../../assets/icon/trash-white.svg"

export default function Subtitles({currentIndex, subtitle, checkSub, player, newSub, addSub, updateSub, removeSub}) {
    const [height, setHeight] = useState(100)
	const $blockRef = useRef(null)
    const {t} = useTranslation()
	const GAP = 0.1;
	const LENGTH = 0.5;

    const resize = useCallback(() => {
		const { height: h } = $blockRef.current?.getBoundingClientRect() || {}
        if (h) setHeight(h)
    }, [$blockRef, setHeight])

    useEffect(() => {
        resize()
        if (!resize.init) {
            resize.init = true
            const debounceResize = debounce(resize, 500)
            window.addEventListener("resize", debounceResize)
        }
    }, [resize])

	const canAddSubtitle = useCallback(({ rowData: previous, index }) => {
		const next = subtitle[index + 1];
		return !next || ((next.startTime - previous.endTime) > (GAP + LENGTH))
	}, [subtitle])

	const addSubtitle = useCallback(({ rowData: previous, index }) => {
		const next = subtitle[index + 1];
		const start = previous.endTime + GAP
		const duration = next ? Math.min(next.startTime - start, LENGTH) : LENGTH
        const newSubtitle = newSub({ start: DT.d2t(start), end:DT.d2t(start + duration), text: t("SUB_TEXT") });
		addSub(index + 1, newSubtitle);
	}, [subtitle, addSub, newSub, t])

	const removeSubtitle = useCallback(({ rowData: sub }) => {
		removeSub(sub)
	}, [removeSub])

    return (
        <div ref={$blockRef} className="subplayer-subtitles">
            <Table
                headerHeight={0}
                width={360}
                height={height}
                rowHeight={80}
                scrollToIndex={currentIndex}
                rowCount={subtitle.length}
                rowGetter={({ index }) => subtitle[index]}
                headerRowRenderer={() => null}
                rowRenderer={(props) => {
					window.requestAnimationFrame(() => {
						const textarea = document.querySelector(`textarea[data-index="${props.index}"]`);
						if( textarea) {
							textarea.style.height = 'auto';
							textarea.style.height = `${textarea.scrollHeight}px`
						}
					})
                    return (
                        <div
                            key={props.key}
                            className={props.className}
                            style={props.style}
                        >
                            <div className={["item", currentIndex === props.index ? "highlight" : "", checkSub(props.rowData) ? "illegal" : "",].join(" ").trim()}>
                                <textarea
									data-index={props.index}
                                    maxLength={200}
                                    spellCheck={false}
                                    className="textarea"
                                    value={unescape(props.rowData.text)}								
                                    onChange={(event) => {
                                        updateSub(props.rowData, {
                                            text: event.target.value,
                                        })
										event.target.style.height = 'auto';
										event.target.style.height = `${event.target.scrollHeight}px`
                                    }}
									onClick={() => {
										if (player) {
											player.pause();
											if (player.duration >= props.rowData.startTime) {
												player.currentTime = props.rowData.startTime + 0.001;
											}
										}
									}}
                                />
								<div className="buttons">
									<button disabled={!canAddSubtitle(props)} onClick={() => addSubtitle(props)} title={t("Add a subtitle")}><img src={plusIcon} alt=""/></button>
									<button onClick={() => removeSubtitle(props)} title={t("Remove subtitle")}><img src={trashIcon} alt=""/></button>
								</div>
							</div>
                       </div>
                    )
                }}
            ></Table>
        </div>
    )
}
