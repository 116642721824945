import {connect} from "react-redux"
import {globalSelector} from "../../redux/selectors/globalSelector"
import {useTranslation} from "react-i18next"

function PopupOverlay({lexiconsState, neurowideDashboardState, projectsState, teamsState, workflowState}) {
    const {t} = useTranslation()
    return (
        <>
        {(lexiconsState.isModal
        || neurowideDashboardState.isModal
        || projectsState.isModal
        || projectsState.isConfirmationModal.modal
        || lexiconsState.isConfirmationModal.modal
        || teamsState.isConfirmationModal.modal
        || workflowState.isConfirmationModal.modal) &&
            <><div className="popup-overlay force-display"></div></>
        }
        <div className="screen-limit-overlay">
            <h2>{t("Neurowide.studio requires a larger screen, please use a desktop browser.")}</h2>
        </div>
        </>
    )
}

export default connect(globalSelector)(PopupOverlay)