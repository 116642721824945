import {connect} from "react-redux"
import {sessionSelector} from "../../redux/selectors/sessionSelector"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import {useTranslation} from "react-i18next"
import {useForm} from "react-hook-form"
import axios from "axios"
import {API_TEAMS} from "../../constants/apiUrls"

function AddTeamForm({sessionState, IS_MODAL, REFRESH_COGNITO_TOKEN, forceUpdate}) {
    const {t} = useTranslation()
    const {register, handleSubmit, formState: {errors}} = useForm()

    const closeModal = () => {
        IS_MODAL(false)
    }

    const handleRequest = async (data) => {
        const bodyRequest = {
            name: data.name,
            licence_start_date: data.licenceStartDate,
            licence_end_date: data.licenceEndDate,
            email: data.administrator,
            max_users: parseInt(data.maxUsers),
            nb_credits: parseInt(data.nbCredits),
            max_transcription_per_project: parseInt(data.maxTranscription),
            max_translation_per_project: parseInt(data.maxTranslation),
            max_duration: parseInt(data.maxDuration),
        }
        currentAuthSession().then((res) => {
            REFRESH_COGNITO_TOKEN(res.idToken.toString(), res.idToken.payload.id_user, res.idToken.payload.id_team ? res.idToken.payload.id_team : undefined, res.idToken.payload.role)
        })
        const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
        axios.post(API_TEAMS, bodyRequest, headersRequest)
        .then(() => {
            IS_MODAL(false)
            forceUpdate()
        }).catch((err) => {
            console.log("error :", err)
        })
    }

    return (
        <>
        <form className="add-team-form white-inputs">
            <div className="category">
                <h3>{t("LICENCE")}</h3>
                <div className="field">
                    <label for="">{t("Licence name")}*</label>
                    <input type="text" id="name" name="name" placeholder={t("Licence name")} {...register("name", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">{t("Number of creators")}*</label>
                    <input type="number" id="max-users" name="max-users" placeholder="Ex. 01" min="1" {...register("maxUsers", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">{t("Start")}*</label>
                    <input type="date" id="licence-start-date" name="licence-start-date" placeholder="" {...register("licenceStartDate", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">{t("End")}*</label>
                    <input type="date" id="licence-end-date" name="licence-end-date" placeholder="" {...register("licenceEndDate", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">Team manager</label>
                    <input type="email" id="administrator" name="administrator" placeholder="example@email.com" {...register("administrator", {required: true})}/>
                </div>
            </div>
            <div className="category">
                <h3>{t("CHARACTERISTICS")}</h3>
                <div className="field">
                    <label for="">{t("Credits")}*</label>
                    <input type="number" id="nb-credits" name="nb-credits" placeholder="Ex. 10" min="1" {...register("nbCredits", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">{t("Number of transcriptions allowed")}*</label>
                    <input type="number" id="max-transcription" name="max-transcription" placeholder="Ex. 03" min="1" {...register("maxTranscription", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">{t("Number of translations allowed")}*</label>
                    <input type="number" id="max-translation" name="max-translation" placeholder="Ex. 03" min="1" {...register("maxTranslation", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">{t("Max audio / video duration")}*</label>
                    <input type="number" id="max-duration" name="max-duration" placeholder="Ex. 15 minutes" min="1" {...register("maxDuration", {required: true})}/>
                </div>
            </div>
            <div className="actions">
                <button className="cancel-btn" onClick={() => closeModal()}>{t("Cancel")}</button>
                <button className="added-btn" onClick={handleSubmit(handleRequest)}>{t("Added")}</button>
            </div>
        </form>
        </>
    )
}

export default connect(sessionSelector, {REFRESH_COGNITO_TOKEN})(AddTeamForm)