import {useReducer, useState, useEffect} from "react"
import {connect} from "react-redux"
import {globalSelector} from "../redux/selectors/globalSelector"
import axios from "axios"
import {API_LEXICONS} from "../constants/apiUrls"
import {REFRESH_COGNITO_TOKEN} from "../redux/actions/sessionActions"
import currentAuthSession from "../aws/cognito/currentAuthSession"
import LexiconsModal from "../sections/lexicons-modal/LexiconsModal"
import LexiconsHeader from "../sections/lexicons-header/LexiconHeader"
import LexiconsContent from "../sections/lexicons-content/LexiconsContent"
import ConfirmationModal from "../components/confirmation-modal/ConfirmationModal"
import {useTranslation} from "react-i18next"
import {toast} from "react-toastify"

function Lexicons({sessionState, lexiconsState}) {
    const {t} = useTranslation()
    const [isRender, forceUpdate] = useReducer(x => x + 1 + 1, 0)
    const [deleteConfirmation, setDeleteConfirmation] = useState(false)

    useEffect(() => {
        if (deleteConfirmation) {
            currentAuthSession().then((res) => {
                REFRESH_COGNITO_TOKEN(res.idToken.toString(), res.idToken.payload.id_user, res.idToken.payload.id_team ? res.idToken.payload.id_team : undefined, res.idToken.payload.role)
            })
            const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
            axios.delete(`${API_LEXICONS}/${lexiconsState.isConfirmationModal.id}`, headersRequest)
            .then(() => {
                toast.success(t("Lexicon deleted"))
                setDeleteConfirmation(false)
                forceUpdate()
            })
            .catch((err) => {
                switch (err.response.data.message) {
                    case "Unable to delete lexicon because it is used by other resources.":
                        toast.error(t("Impossible to delete the lexicon because of its use in a project!"))
                        break
                    default:
                        toast.error(t("An error has occurred"))
                        break
                }
                setDeleteConfirmation(false)
                forceUpdate()
            })
        }
    }, [deleteConfirmation])

    return (
        <>
        <div className="VIEW LEXICONS">
            {lexiconsState.isConfirmationModal.modal && <ConfirmationModal type={"lexicons"} setDeleteConfirmation={setDeleteConfirmation}/>}
            {lexiconsState.isModal && <><LexiconsModal type={lexiconsState.modalType} forceUpdate={forceUpdate}/></>}
            <LexiconsHeader/>
            <span className="line"></span>
            <LexiconsContent isRender={isRender} deleteConfirmation={deleteConfirmation}/>
        </div>
        </>
    )
}

export default connect(globalSelector)(Lexicons)