import {useState, useEffect} from "react"
import {connect} from "react-redux"
import {sessionSelector} from "../../redux/selectors/sessionSelector"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import {useParams} from "react-router-dom"
import axios from "axios"
import {API_PROJECTS} from "../../constants/apiUrls"
import {useTranslation} from "react-i18next"
import {useForm} from "react-hook-form"
import Dropzone from "../../components/dropzone/Dropzone"
import useProject from "../../swrCustomHooks/useProject"
import useTeam from "../../swrCustomHooks/useTeam"
import LoadingComponent from "../../components/loading-component/LoadingComponent"
import {toast} from "react-toastify"

function UpdateProjectsForm({sessionState, REFRESH_COGNITO_TOKEN, setWorkflowNavigation, setIsPulling}) {
    const {t} = useTranslation()
    const params = useParams()
    const {project, isLoadingProject, isErrorProject} = useProject(sessionState.idToken, params.projectID)
    const {team, isLoadingTeam, isErrorTeam} = useTeam(sessionState.idTeam, sessionState.idToken)
    const {register, handleSubmit, formState: {errors}} = useForm({defaultValues: {
        isPrivate: !project.is_public,
        langCode: project.lang_code,
        title: project.title,
    }})
    const [uploadFile, setUploadFile] = useState()

    const updateProject = async (data) => {
        const bodyRequest = {
            is_public: !data.isPrivate,
            lang_code: data.langCode,
            title: data.title
        }
        currentAuthSession().then((res) => {
            REFRESH_COGNITO_TOKEN(res.idToken.toString(), res.idToken.payload.id_user, res.idToken.payload.id_team ? res.idToken.payload.id_team : undefined, res.idToken.payload.role)
        })
        const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
        axios.put(`${API_PROJECTS}/${params.projectID}`, bodyRequest, headersRequest)
        .then(() => {
            if (uploadFile) {
                setIsPulling(true)
                axios.post(`${API_PROJECTS}/${params.projectID}/tries`, {step: "upload", filename: uploadFile.name},  headersRequest)
                .then((res) => {
                    axios.put(res.data.uploadUrl, uploadFile)
                    .then(() => {
                        toast.success(t("Upload completed!"))
                    })
                    .catch(() => {
                        toast.error(t("An error has occurred"))
                    })
                })
                .catch((err) => {
                    console.log("error :", err)
                })
            }
            toast.success(t("The changes have been applied!"))
            setWorkflowNavigation(1)
        })
        .catch(() => {
            toast.error(t("An error has occurred"))
        })
    }

    useEffect(() => {
        currentAuthSession().then((res) => {
            REFRESH_COGNITO_TOKEN(res.idToken.toString(), res.idToken.payload.id_user, res.idToken.payload.id_team ? res.idToken.payload.id_team : undefined)
        })
    }, [])

    if (isErrorTeam || isErrorProject || isLoadingTeam || isLoadingProject || team.message || project.message) return <LoadingComponent/>
    return (
        <>
         <form className="projects-form" onSubmit={handleSubmit(updateProject)}>
            <div className="left-content">
                <h3>{`${t("Project information")}*`}</h3>
                <span className="switch-label">{t("Private")}</span>
                <label className="switch">
                    <input type="checkbox" id="is-private" name="is-private" {...register("isPrivate", {type: Boolean})}/>
                    <span className="slider round"></span>
                </label>
                <div className="field">
                    <label for="">{`${t("Project name")}*`}</label>
                    <input type="text" id="title" name="title" placeholder={t("Ex. Project 01")} {...register("title", {required: true, maxLength: 75})}/>
                </div>
                <div className="field">
                    <label for="">{`${t("Source language")}*`}</label>
                        {project.transcription_status === "done" ?
                            <>
                            <select name="lang-code" id="lang-code" {...register("langCode", {required: true})} disabled>
                                <option value="fr">{t("French")}</option>
                                <option value="en">{t("English")}</option>
                            </select>
                            </>
                            :
                            <>
                            <select name="lang-code" id="lang-code" {...register("langCode", {required: true})}>
                                <option value="fr">{t("French")}</option>
                                <option value="en">{t("English")}</option>
                            </select>
                            </>
                        }
                </div>
                <span className="mandatory">{`*${t("Required fields")}`}</span>
            </div>
            <div className="right-content">
                <Dropzone setUploadFile={setUploadFile}
                maxSize={500000000}
                maxDuration={team.team && team.team.max_duration * 60}
                transcriptionStatus={project.transcription_status}
                fileName={project.filename}/>
            </div>
            <div className="buttons">
                <button className="create-project-btn">{t("Update")}</button>
            </div>
        </form>
        </>
    )
}

export default connect(sessionSelector, {REFRESH_COGNITO_TOKEN})(UpdateProjectsForm)