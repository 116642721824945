import {useState, useEffect} from "react"
import {connect} from "react-redux"
import {globalSelector} from "../../redux/selectors/globalSelector"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import {useTranslation} from "react-i18next"
import axios from "axios"
import {API_PROJECTS} from "../../constants/apiUrls"
import useLexicons from "../../swrCustomHooks/useLexicons"
import Select from "react-select"
import LoadingComponent from "../../components/loading-component/LoadingComponent"

function SelectLexiconsModalForm({sessionState, projectsState, IS_MODAL, REFRESH_COGNITO_TOKEN, forceUpdate}) {
    const {t} = useTranslation()
    const {lexicons, isLoadingLexicons, isErrorLexicons} = useLexicons(sessionState.idToken)
    const [selectValue, setSelectValue] = useState()
    const options = []

    try {
        for (let lexicon of lexicons) {
            options.push({value: lexicon.id, label: lexicon.name})
        }
    } catch {}

    const selectLexiconProject = (event) => {
        event.preventDefault()
        currentAuthSession().then((res) => {
            REFRESH_COGNITO_TOKEN(res.idToken.toString(), res.idToken.payload.id_user, res.idToken.payload.id_team ? res.idToken.payload.id_team : undefined, res.idToken.payload.role)
        })
        const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
        axios.post(`${API_PROJECTS}/${projectsState.projectID}/lexicons/${selectValue.value}`, null, headersRequest)
        .then((res) => {
            axios.put(`${API_PROJECTS}/${projectsState.projectID}`, {lexicon_status: "done"}, headersRequest)
            .then(() => {
                IS_MODAL(false)
                forceUpdate()
            })
            .catch((err) => {
                console.log("error :", err)
            })
        })
        .catch((err) => {
            console.log("error :", err)
        })
    }

    const closeModal = () => {
        IS_MODAL(false)
    }

    useEffect(() => {
        currentAuthSession().then((res) => {
            REFRESH_COGNITO_TOKEN(res.idToken.toString(), res.idToken.payload.id_user, res.idToken.payload.id_team ? res.idToken.payload.id_team : undefined)
        })
    }, [])

    if (isErrorLexicons || isLoadingLexicons || lexicons.message) return <LoadingComponent/>
    return (
        <>
        <form className="lexicons-modal-form">
            <div className="field">
                <Select className="select" options={options} onChange={(value) => setSelectValue(value)}/>
            </div>
            <div className="actions">
                <button className="cancel-btn" onClick={closeModal}>{t("Cancel")}</button>
                <button className="append-btn" onClick={selectLexiconProject}>{t("Select")}</button>
            </div>
        </form>
        </>
    )
}

export default connect(globalSelector, {REFRESH_COGNITO_TOKEN})(SelectLexiconsModalForm)