import {useState, useReducer, useEffect} from "react"
import {connect} from "react-redux"
import {globalSelector} from "../../redux/selectors/globalSelector"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import {useParams, useNavigate} from "react-router-dom"
import useTeam from "../../swrCustomHooks/useTeam"
import LoadingComponent from "../../components/loading-component/LoadingComponent"
import TeamHeader from "../../sections/team-header/TeamHeader"
import TeamContent from "../../sections/team-content/TeamContent"
import ConfirmationModal from "../../components/confirmation-modal/ConfirmationModal"
import axios from "axios"
import {API_TEAMS} from "../../constants/apiUrls"
import RequireIsAdmin from "../../tools/RequireIsAdmin"

function Team({sessionState, teamsState}) {
    let params = useParams()
    const navigate = useNavigate()
    const [isRender, forceUpdate] = useReducer(x => x + 1 + 1, 0)
    const [deleteConfirmation, setDeleteConfirmation] = useState(false)
    const {team, mutateTeam, isLoadingTeam, isErrorTeam} = useTeam(params.teamID, sessionState.idToken)

    useEffect(() => {
        currentAuthSession().then((res) => {
            REFRESH_COGNITO_TOKEN(res.idToken.toString(), res.idToken.payload.id_user, res.idToken.payload.id_team ? res.idToken.payload.id_team : undefined, res.idToken.payload.role)
        })
        mutateTeam()
        if (deleteConfirmation) {
            const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
            axios.delete(`${API_TEAMS}/${teamsState.isConfirmationModal.id}`, headersRequest)
            .then(() => {
                setDeleteConfirmation(false)
                forceUpdate()
                navigate("/neurowide-dashboard")
            })
            .catch((err) => {
                console.log("error :", err)
            })
        }
    }, [isRender, deleteConfirmation])

    if (isErrorTeam || isLoadingTeam || team.message) return <LoadingComponent/>
    return (
        <>
        <RequireIsAdmin role={sessionState.role} redirect={true}>
            <div className="VIEW TEAM">
                {teamsState.isConfirmationModal.modal && <ConfirmationModal type={"teams"} setDeleteConfirmation={setDeleteConfirmation}/>}
                <TeamHeader teamID={team.team.id} name={team.team.name}/>
                <TeamContent team={team.team} forceUpdate={forceUpdate}/>
            </div>
        </RequireIsAdmin>
        </>
    )
}

export default connect(globalSelector)(Team)