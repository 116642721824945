import * as types from "../redux-constants/projectsConstants"

const projects = {
    isModal: false,
    isConfirmationModal: {modal: false, id: null, action: null},
    tab: "my-projects",
    projectID: null,
}

export default function ProjectsReducer(state=projects, action) {
    switch(action.type) {
        case types.IS_MODAL:
            return {
                isModal: action.payload.isModal,
                isConfirmationModal: state.isConfirmationModal,
                tab: state.tab,
                projectID: state.projectID,
            }
        case types.PROJECTS_IS_CONFIRMATION_MODAL:
            return {
                isModal: state.isModal,
                isConfirmationModal: action.payload.isConfirmationModal,
                tab: state.tab,
                projectID: state.projectID,
            }
        case types.SET_TAB:
            return {
                isModal: state.isModal,
                isConfirmationModal: state.isConfirmationModal,
                tab: action.payload.tab,
                projectID: state.projectID,
            }
        case types.SET_PROJECT_ID:
            return {
                isModal: state.isModal,
                isConfirmationModal: state.isConfirmationModal,
                tab: state.tab,
                projectID: action.payload.projectID,
            }
        default:
            return state
    }
}