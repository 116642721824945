import {connect} from "react-redux"
import {useNavigate} from "react-router-dom"
import {globalSelector} from "../../redux/selectors/globalSelector"
import ProjectTag from "../project-tag/ProjectTag"
import ParseDate from "../../tools/ParseDate"
import fileIcon from "../../assets/icon/file.svg"
import downloadIcon from "../../assets/icon/download.svg"
import ProfilePicture from "../profile-picture/ProfilePicture"
import useTries from "../../swrCustomHooks/useTries"
import trashIcon from "../../assets/icon/trash.svg"
import replayIcon from "../../assets/icon/replay.svg"
import {PROJECTS_IS_CONFIRMATION_MODAL} from "../../redux/actions/projectsActions"
import axios from "axios"
import {API_PROJECTS} from "../../constants/apiUrls"

function ProjectCard({sessionState, projectsState, PROJECTS_IS_CONFIRMATION_MODAL, project, type}) {
    const {tries, isLoadingTries, isErrorTries} = useTries(sessionState.idToken, project.id)
    const navigate = useNavigate()

    const selectProject = (e) => {
        e.stopPropagation()
        navigate(`/workflow/${project.id}`)
    }

    const downloadProject = (e) => {
        e.stopPropagation()
        const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
        axios.get(`${API_PROJECTS}/${project.id}/archive.zip`, headersRequest, {maxRedirects: 0})
        .then((res) => {
            var link = document.createElement("a")
            link.href = res.data
            link.click()
        })
        .catch((err) => {
            console.log("error :", err)
        })
    }

    const deleteProject = (e) => {
        e.stopPropagation()
        PROJECTS_IS_CONFIRMATION_MODAL({modal: true, id: project.id, action: "delete"})
    }

    const restoreProject = (e) => {
        e.stopPropagation()
        PROJECTS_IS_CONFIRMATION_MODAL({modal: true, id: project.id, action: "restore"})
    }

    if (!project.owner || isErrorTries || isLoadingTries || tries.message) return ""
    return (
        <>
        <div className="project-card" onClick={type !== "recycle-bin" ? selectProject : () => {}}>
            <img className="file" src={fileIcon} alt=""/>
            <p className="name" title={project.title}>{project.title}</p>
            <div className="tag">
                <ProjectTag type={project.deleted ? "delete" : project.finished ? "finished" : tries[0].step}/>
            </div>
            <div className="tag">
                <ProjectTag type={project.deleted ? "private" : project.is_public ? "public" : "private"}/>
            </div>
            <p className="date">{ParseDate(project.created_at)}</p>
            <ProfilePicture idUser={project.owner.id}/>
            <p className="username">{`${project.owner.firstname} ${project.owner.lastname}`}</p>
            <div className="actions">
                {(project.archive_status === "done" && project.finished) && type !== "recycle-bin" && <><img className="download" src={downloadIcon} alt="" onClick={downloadProject}/></>}
                {type === "recycle-bin" ?
                    <>
                    <img className="restore" src={replayIcon} alt="" onClick={restoreProject}/>
                    </>
                :
                    <>
                    <img className="delete" src={trashIcon} alt="" onClick={deleteProject}/>
                    </>
                }
            </div>
        </div>
        </>
    )
}

export default connect(globalSelector, {PROJECTS_IS_CONFIRMATION_MODAL})(ProjectCard)