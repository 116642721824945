import {signIn} from "aws-amplify/auth"
import axios from "axios"
import {API_USERS} from "../../constants/apiUrls"

export default async function login(formData, t, setError) {
    try {
        return await signIn({username: formData.email, password: formData.password})
    } catch (error) {
        if (error.message === "Temporary password has expired and must be reset by an administrator.") {
            axios.post(`${API_USERS}/reactivate`, {email: formData.email})
            .then((res) => {
                console.log("res :", res)
                setError("mail", {
                    type: "manual",
                    message: t("Your temporary password has been reset."),
                })
            })
            .catch((error) => {
                console.log("error :", error)
            })
        }
        console.log(error.message)
    }
}