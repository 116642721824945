import {connect} from "react-redux"
import {projectsSelector} from "../../redux/selectors/projectsSelector"
import {PROJECTS_IS_CONFIRMATION_MODAL} from "../../redux/actions/projectsActions"
import {LEXICONS_IS_CONFIRMATION_MODAL} from "../../redux/actions/lexiconsActions"
import {TEAMS_IS_CONFIRMATION_MODAL} from "../../redux/actions/teamsActions"
import {WORKFLOW_IS_CONFIRMATION_MODAL} from "../../redux/actions/worfklowActions"
import {useTranslation} from "react-i18next"

function ConfirmationModal({projectsState,
    PROJECTS_IS_CONFIRMATION_MODAL,
    LEXICONS_IS_CONFIRMATION_MODAL,
    TEAMS_IS_CONFIRMATION_MODAL,
    WORKFLOW_IS_CONFIRMATION_MODAL,
    type,
    setDeleteConfirmation,
    setRestoreConfirmation,
    setExportConfirmation,
    modalMessage}) {
    const {t} = useTranslation()

    const handleClick = (value) => {
        switch (type) {
            case "projects":
                projectsState.isConfirmationModal.action === "restore" ? setRestoreConfirmation(value) : setDeleteConfirmation(value)
                PROJECTS_IS_CONFIRMATION_MODAL({modal: false, id: null, action: null})
                break
            case "lexicons":
                setDeleteConfirmation(value)
                LEXICONS_IS_CONFIRMATION_MODAL({modal: false, id: null, action: null})
                break
            case "teams":
                setDeleteConfirmation(value)
                TEAMS_IS_CONFIRMATION_MODAL({modal: false, id: null, action: null})
                break
            case "export":
                setExportConfirmation(value)
                WORKFLOW_IS_CONFIRMATION_MODAL({modal: false, id: null})
                break
            default:
                break
        }
    }

    return (
        <>
        <div className="confirmation-modal">
            <div className="content">
                <h2>{t(modalMessage ? modalMessage : "Do you want to confirm this action?")}</h2>
                <div className="actions">
                    <button className="cancel-btn" onClick={() => handleClick(true)}>{t("Yes")}</button>
                    <button className="confirm-btn" onClick={() => handleClick(false)}>{t("No")}</button>
                </div>
            </div>
        </div>
        </>
    )
}

export default connect(projectsSelector, {PROJECTS_IS_CONFIRMATION_MODAL, LEXICONS_IS_CONFIRMATION_MODAL, TEAMS_IS_CONFIRMATION_MODAL, WORKFLOW_IS_CONFIRMATION_MODAL})(ConfirmationModal)