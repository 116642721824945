import {useTranslation} from "react-i18next"
import {connect} from "react-redux"
import {IS_MODAL} from "../../redux/actions/lexiconsActions"

import plusIcon from "../../assets/icon/plus.svg"

function SelectLexiconsBtn({IS_MODAL}) {
    const {t} = useTranslation()
    return (
        <>
        <button className="select-lexicon-btn" onClick={() => IS_MODAL(true, "select")}><img src={plusIcon} alt=""/>{t("Select a lexicon")}</button>
        </>
    )
}

export default connect(null, {IS_MODAL})(SelectLexiconsBtn)