import {useEffect} from "react"
import SigninForm from "../forms/authentification/SigninForm"
import {connect} from "react-redux"
import {sessionSelector} from "../redux/selectors/sessionSelector"

function Signin({sessionState}) {

    useEffect(() => {
        sessionState.isLogin && window.location.reload()
    })

    return (
        <>
        <div className="VIEW SIGNIN">
            <SigninForm/>
        </div>
        </>
    )
}

export default connect(sessionSelector)(Signin)