import {useState} from "react"
import {connect} from "react-redux"
import {sessionSelector} from "../../redux/selectors/sessionSelector"
import {LOGIN} from "../../redux/actions/sessionActions"
import {useForm} from "react-hook-form"
import {useNavigate} from "react-router-dom"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import login from "../../aws/cognito/login"
import firstLogin from "../../aws/cognito/firstLogin"
import sendConfirmationCode from "../../aws/cognito/sendConfirmationCode"
import changePassword from "../../aws/cognito/changePassword"
import {useTranslation} from "react-i18next"

function SigninForm({LOGIN}) {
    const {t} = useTranslation()
    const {register, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({defaultValues: {oldPassword: "", newPassword: ""}})
    const [resetPassword, setResetPassword] = useState(false)
    const [sendCode, setSendCode] = useState(false)
    const [firstAuth, setFirstAuth] = useState(false)
    let navigate = useNavigate()

    const handleRequest = async (data) => {
        if (resetPassword) {
            changePassword(data)
            window.location.reload()
        } else if (firstAuth){
            firstLogin(data)
            .then((res) => {
                setFirstAuth(false)
                LOGIN(res.idToken.toString(), res.idToken.payload.id_user, res.idToken.payload.id_team ? res.idToken.payload.id_team : undefined, res.idToken.payload.role)
                navigate("/")
            })
            .catch((err) => {
                console.log("error :", err)
            })
        } else {
            await login(data, t, setError)
            .then(async (res) => {
                try {
                    if (res.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
                        setFirstAuth(true)
                    }
                } catch {}
                await currentAuthSession()
                .then((res) => {
                    if (res.accessToken !== undefined) {
                        LOGIN(res.idToken.toString(), res.idToken.payload.id_user, res.idToken.payload.id_team ? res.idToken.payload.id_team : undefined, res.idToken.payload.role)
                        navigate("/")
                        .catch((err) => {
                            console.log("error :", err)
                        })
                    } else {
                        setError("mail", {
                            type: "manual",
                            message: t("Incorrect email or password"),
                        })
                    }
                })
                .catch((err) => {
                    console.log("erorr :", err)
                })
            })
        }
    }

    const forgotPassword = (data) => {
        clearErrors()
        if (sendCode) {
            sendConfirmationCode(data.email)
            .then(() => {
                setSendCode(false)
                setResetPassword(true)
            })
            .catch((err) => {
                console.log("error :", err)
            })
        } else {
            setSendCode(true)
        }
    }

    return (
        <>
        {sendCode ?
            <>
            <form className="signin-form" onSubmit={handleSubmit(forgotPassword)}>
                <h2>{t("Forgot password")}</h2>
                <div className="field">
                    <label for="">Email</label>
                    <input className={`${errors.email ? "not-valid" : ""}`} type="text" id="email" name="email" placeholder="email.exemple@gmail.com" {...register("email", {required: true, pattern: /^([a-zA-Z0-9_\-\.+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]+)$/})}/>
                </div>
                <button>{t("Get my reset code")}</button>
            </form>
            </>
        :
        <>
        {firstAuth ?
            <>
            <form className="signin-form" onSubmit={handleSubmit(handleRequest)}>
                <h2>{t("New password")}</h2>
                {errors.newPassword && <p className="error-message">{t("Your password must contain between 8 and 15 characters including at least one number, one capital letter and a special character.")}</p>}
                <div className="field">
                    <label for="">Email</label>
                    <input className={`${errors.email ? "not-valid" : ""}`} type="text" id="email" name="email" placeholder="email.exemple@gmail.com" {...register("email", {required: true, pattern: /^([a-zA-Z0-9_\-\.+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]+)$/})}/>
                </div>
                <div className="field">
                    <label for="">{t("Temporary password")}</label>
                    <input className={`${errors.oldPassword ? "not-valid" : ""}`} type="password" id="oldPassword" name="oldPassword" placeholder="*****************" {...register("oldPassword", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">{t("New password")}</label>
                    <input className={`${errors.newPassword ? "not-valid" : ""}`} type="password" id="newPassword" name="newPassword" placeholder="*****************" {...register("newPassword", {required: true, pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/})}/>
                </div>
                <button onClick={() => clearErrors("mail")}>{t("Send")}</button>
            </form>
            </>
        :
        resetPassword ?
            <>
            <form className="signin-form" onSubmit={handleSubmit(handleRequest)}>
                {errors.newPassword && <p className="error-message">{t("Your password must contain between 8 and 15 characters including at least one number, one capital letter and a special character.")}</p>}
                <h2>{t("Forgot password")}</h2>
                <div className="field">
                    <label for="">Email</label>
                    <input className={`${errors.email ? "not-valid" : ""}`} type="text" id="email" name="email" placeholder="email.exemple@gmail.com" {...register("email", {required: true, pattern: /^([a-zA-Z0-9_\-\.+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]+)$/})}/>
                </div>
                <div className="field">
                    <label for="">{t("Validation code")}</label>
                    <input className={`${errors.code ? "not-valid" : ""}`} type="text" id="code" name="code" placeholder="000000" {...register("code", {required: true, pattern: /^[0-9]{6}$/})}/>
                </div>
                <div className="field">
                    <label for="">{t("New password")}</label>
                    <input className={`${errors.newPassword ? "not-valid" : ""}`} type="password" id="newPassword" name="password" placeholder="*****************" {...register("newPassword", {required: true, pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/})}/>
                </div>
                <button onClick={() => clearErrors("mail")}>{t("Send")}</button>
            </form>
            </>
        :
            <>
            <form className="signin-form" onSubmit={handleSubmit(handleRequest)}>
                <h2>{t("Log In to Neurowide.studio")}</h2>
                {errors.mail && <p className="error-message">{errors.mail.message}</p>}
                <div className="field">
                    <label for="">Email</label>
                    <input className={`${errors.email ? "not-valid" : ""}`} type="text" id="email" name="email" placeholder="email.exemple@gmail.com" {...register("email", {required: true, pattern: /^([a-zA-Z0-9_\-\.+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]+)$/})}/>
                </div>
                <div className="field">
                    <label for="">{t("Password")}</label>
                    <input className={`${errors.password ? "not-valid" : ""}`} type="password" id="password" name="password" placeholder="*****************" {...register("password", {required: true})}/>
                </div>
                <div className="field">
                    <span onClick={forgotPassword}>{t("Forgot password?")}</span>
                </div>
                <button onClick={() => clearErrors("mail")}>{t("Log In")}</button>
            </form>
            </>}
        </>}
    </>
    )
}

export default connect(sessionSelector, {LOGIN})(SigninForm)