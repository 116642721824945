import {useState, useEffect, createRef, useCallback, useMemo, memo} from "react"
import TextareaAutosize from "react-textarea-autosize"
import {useTranslation} from "react-i18next"
// import backlight from "../../libs/backlight"
import {isPlaying} from "../../utils"

import playIcon from "../../../assets/icon/play.svg"
import pauseIcon from "../../../assets/icon/pause.svg"
import forwardIcon from "../../../assets/icon/forward.svg"
import rewindIcon from "../../../assets/icon/rewind.svg"

const VideoWrap = memo(({setPlayer, setDuration, currentTime,  setCurrentTime, playing, setPlaying, mediaUrl}) => {
    const $video = createRef()

    useEffect(() => {
        try {
            setPlayer($video.current)
			$video.current.currentTime = currentTime;
			if (playing) $video.current.play()
        } catch {}
        (function loop() {
            window.requestAnimationFrame(() => {
                if ($video.current) {
                    setPlaying(isPlaying($video.current))
                    setCurrentTime($video.current.currentTime || currentTime)
					setDuration($video.current.duration)
                }
                loop()
            })
        })()
    }, [setPlayer, setCurrentTime, setPlaying, $video, mediaUrl])

    const onClick = useCallback(() => {
        if ($video.current) {
            if (isPlaying($video.current)) {
                $video.current.pause()
            } else {
                $video.current.play()
            }
        }
    }, [$video])
	
    return <video key={mediaUrl} crossOrigin="anonymous" onClick={onClick} src={mediaUrl} ref={$video}/>
}, (previous, current) => (previous.mediaUrl === current.mediaUrl))

export default function Player(props) {
    const {t} = useTranslation()
    const [currentSub, setCurrentSub] = useState(null)
    const [focusing, setFocusing] = useState(false)
    const [inputItemCursor, setInputItemCursor] = useState(0)
	const [displayIcon, setDisplayIcon] = useState(null)
    const $player = createRef()

    useMemo(() => {
        setCurrentSub(props.subtitle[props.currentIndex]);
    }, [props.subtitle, props.currentIndex])

    const onChange = useCallback(
        (event) => {
            props.player.pause();
            props.updateSub(currentSub, {text: event.target.value})
            if (event.target.selectionStart) {
                setInputItemCursor(event.target.selectionStart)
            }
        },
        [props, currentSub])

    const onClick = useCallback(
        (event) => {
            props.player.pause()
            if (event.target.selectionStart) {
                setInputItemCursor(event.target.selectionStart)
            }
        },
        [props])

    const onFocus = useCallback((event) => {
        setFocusing(true)
        if (event.target.selectionStart) {
            setInputItemCursor(event.target.selectionStart)
        }
    }, [])

    const onBlur = useCallback(() => {
        setTimeout(() => setFocusing(false), 500)
    }, [])

    const onSplit = useCallback(() => {
        props.splitSub(currentSub, inputItemCursor)
    }, [props, currentSub, inputItemCursor])

	useEffect(() => {
        setDisplayIcon(true)
		setTimeout(() => setDisplayIcon(false), 1000)
    }, [props.playing])

    return (
        <div className="subplayer-player">
            <div className="video" ref={$player}>
                <VideoWrap {...props}/>
				<div className={['icon', props.playing ? 'play' : 'pause', displayIcon ? 'force' : ''].join(" ").trim()}>
					<img src={playIcon} alt=""/>
					<img src={pauseIcon} alt=""/>
				</div>
				<div className={['icon', 'rwd', props.goRewind ? 'show' : ''].join(" ").trim()}><img src={rewindIcon} alt=""/><span>1s</span></div>
				<div className={['icon', 'fwd', props.goForward ? 'show' : ''].join(" ").trim()}><img src={forwardIcon} alt=""/><span>1s</span></div>
                {props.player && currentSub ? (
                    <div className={['subtitle', !props.playing ? 'pause' : ''].join(" ").trim()}>
                        {focusing ? (
                            <div className="operate" onClick={onSplit}>
                                {t("SPLIT")}
                            </div>
                        ) : null}
						<div class="block">
                        <TextareaAutosize
                            className="textarea"
                            value={currentSub.text}
                            onChange={onChange}
                            onClick={onClick}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onKeyDown={onFocus}
                            spellCheck={false}
                        />
						</div>
                    </div>
                ) : null}
            </div>
        </div>
    )
}
