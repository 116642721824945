import {useState, useEffect} from "react"
import {NavLink, Link} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {connect} from "react-redux"
import {globalSelector} from "../../redux/selectors/globalSelector"
import {HANDLE_TYPE} from "../../redux/actions/localActions"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import axios from "axios"
import {API_TEAMS} from "../../constants/apiUrls"
import neurowideStudioLogo from "../../assets/neurowide-studio.svg"
import neurowideStudioMin from "../../assets/neurowide-studio-min.svg"
import doubleArrowLeftIcon from "../../assets/icon/double-arrow-left.svg"
import homeNavIcon from "../../assets/icon/home-nav.svg"
import projectsNavIcon from "../../assets/icon/projects-nav.svg"
import lexiconsNavIcon from "../../assets/icon/lexicons-nav.svg"
import manageAccountIcon from "../../assets/icon/manage-account.svg"
import teamNavIcon from "../../assets/icon/team-nav.svg"
import supportNavIcon from "../../assets/icon/support-nav.svg"
import creditsNavIcon from "../../assets/icon/credits-nav.svg"
import RequireIsManager from "../../tools/RequireIsManager"
import RequireIsAdmin from "../../tools/RequireIsAdmin"

const getCredits = (idTeam, headersRequest) => {
    return axios.get(`${API_TEAMS}/${idTeam}`, headersRequest)
    .then((res) => {
        return res.data.team.nb_credits
    })
    .catch ((err) => {
        console.log("error : ", err)
    })
}

function MainNavigation({sessionState, localState, HANDLE_TYPE}) {
    const {t} = useTranslation()
    let [minNavigation, setMinNavigation] = useState(localState.minNavigation)
    let [credits, setCredits] = useState()
    const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
    try {
        getCredits(sessionState.idTeam, headersRequest).then((res) => {
            setCredits(res)
        })
    } catch {}

    const handleClick = () => {
        HANDLE_TYPE(!minNavigation)
        setMinNavigation(!minNavigation)
    }

    useEffect(() => {
        currentAuthSession().then((res) => {
            REFRESH_COGNITO_TOKEN(res.idToken.toString(), res.idToken.payload.id_user, res.idToken.payload.id_team ? res.idToken.payload.id_team : undefined, res.idToken.payload.role)
        })
    }, [])

    return (
        <>
        <div className={`SECTION ${minNavigation ? "min-nw-left-nav" : "nw-left-nav"}`}>
            <img className="nw-logo" src={minNavigation ? neurowideStudioMin : neurowideStudioLogo} alt=""/>
            <img onClick={handleClick} className="nav-action-btn" src={doubleArrowLeftIcon} alt=""/>
            <span className="line"></span>
            <nav>
                <ul className="top-nav">
                    <li><NavLink to="/"><img src={homeNavIcon} alt=""/>{minNavigation ? "" : t("Home")}</NavLink></li>
                    <li><NavLink to="/projects"><img src={projectsNavIcon} alt=""/>{minNavigation ? "" : t("Projects")}</NavLink></li>
                    <li><NavLink to="/lexicons"><img src={lexiconsNavIcon} alt=""/>{minNavigation ? "" : t("Lexicons")}</NavLink></li>
                    <RequireIsManager role={sessionState.role}>
                        <li><NavLink to="/team-management"><img src={teamNavIcon} alt=""/>{minNavigation ? "" : t("Team management")}</NavLink></li>
                    </RequireIsManager>
                    <RequireIsAdmin role={sessionState.role}>
                        <li><NavLink to="/neurowide-dashboard"><img src={manageAccountIcon} alt=""/>{minNavigation ? "" : t("Neurowide dashboard")}</NavLink></li>
                    </RequireIsAdmin>
                </ul>
                <ul className="bottom-nav">
                    <li className="support"><Link to="/"><img src={supportNavIcon} alt=""/>{minNavigation ? "" : "Support"}</Link></li>
                    <li className="credit"><Link to="/"><img src={creditsNavIcon} alt=""/>{minNavigation ? "" : `${credits} ${t("credits available")}`}</Link></li>
                </ul>
            </nav>
        </div>
        </>
    )
}

export default connect(globalSelector, {HANDLE_TYPE})(MainNavigation)