import {useState, useEffect, useCallback, useMemo, useRef} from "react"
import DT from "duration-time-conversion"
import isEqual from "lodash/isEqual"
import {useTranslation} from "react-i18next"
import { ToastContainer } from "react-toastify"
// import Tool from "./components/tool/Tool"
import Subtitles from "./components/subtitles/Subtitles"
import Player from "./components/player/Player"
import Footer from "./components/footer/Footer"
import Loading from "./components/loading/Loading"
import ProgressBar from "./components/progress-bar/ProgressBar"
import {getKeyCode} from "./utils"
import Sub from "./libs/Sub"
import {file2sub, sub2vtt, sub2srt, sub2txt} from "./libs/readSub"
// import sub2ass from "./libs/readSub/sub2ass"
// import {getExt, download} from "./utils"
import fullscreenIcon from "../assets/icon/fullscreen.svg"
import fullscreenExitIcon from "../assets/icon/fullscreen_exit.svg"
import saveIcon from "../assets/icon/save.svg"

export default function SubPlayer({defaultLang, mediaUrl, audioUrl, srtFile, setLatestSrt, save}) {
    const {t} = useTranslation()
    const subtitleHistory = useRef([])
    const editor = useRef(null)
    const [player, setPlayer] = useState(null)
    const [loading, setLoading] = useState("")
    const [processing, setProcessing] = useState(0)
    const [language, setLanguage] = useState(defaultLang)
    const [subtitle, setSubtitleOriginal] = useState([])
    const [waveform, setWaveform] = useState(null)
    const [playing, setPlaying] = useState(false)
    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(0)
    const [currentIndex, setCurrentIndex] = useState(-1)
    const [goForward, setGoForward] = useState(false);
    const [goRewind, setGoRewind] = useState(false);

    const newSub = useCallback((item) => new Sub(item), [])
    const hasSub = useCallback((sub) => subtitle.indexOf(sub), [subtitle])

    const formatSub = useCallback(
        (sub) => {
            if (Array.isArray(sub)) {
                return sub.map((item) => newSub(item))
            }
            return newSub(sub)
        },
        [newSub],
    )

    const copySubs = useCallback(() => formatSub(subtitle), [subtitle, formatSub])

    const setSubtitle = useCallback(
        (newSubtitle, saveToHistory = true) => {
            if (!isEqual(newSubtitle, subtitle)) {
                if (saveToHistory) {
                    if (subtitleHistory.current.length >= 1000) {
                        subtitleHistory.current.shift()
                    }
                    subtitleHistory.current.push(formatSub(subtitle))
                }
                window.localStorage.setItem("subtitle", JSON.stringify(newSubtitle))
                setSubtitleOriginal(newSubtitle)
            }
        },
        [subtitle, setSubtitleOriginal, formatSub],
    )

    const undoSubs = useCallback(() => {
        const subs = subtitleHistory.current.pop()
        if (subs) {
            setSubtitle(subs, false)
        }
    }, [setSubtitle, subtitleHistory])

    const clearSubs = useCallback(() => {
        setSubtitle([])
        subtitleHistory.current.length = 0
    }, [setSubtitle, subtitleHistory])

    const checkSub = useCallback(
        (sub) => {
            const index = hasSub(sub)
            if (index < 0) return
            const previous = subtitle[index - 1]
            return (previous && sub.startTime < previous.endTime) || !sub.check || sub.duration < 0.2
        },
        [subtitle, hasSub],
    )

    const onSubtitleChange = useCallback((srtFile) => {
        const file = srtFile
        if (file) {
            file2sub(file)
                .then((res) => {
                    clearSubs()
                    setSubtitle(res)
                })
                .catch((err) => {
                    console.log("error :", err)
                })
        }
    }, [setSubtitle, clearSubs])

    const removeSub = useCallback(
        (sub) => {
            const index = hasSub(sub)
            if (index < 0) return
            const subs = copySubs()
            subs.splice(index, 1)
            setSubtitle(subs)
        },
        [hasSub, copySubs, setSubtitle],
    )

    const addSub = useCallback(
        (index, sub) => {
            const subs = copySubs()
            subs.splice(index, 0, formatSub(sub))
            setSubtitle(subs)
        },
        [copySubs, setSubtitle, formatSub],
    )

    const updateSub = useCallback(
        (sub, obj) => {
            const index = hasSub(sub)
            if (index < 0) return
            const subs = copySubs()
            const subClone = formatSub(sub)
            Object.assign(subClone, obj)
            if (subClone.check) {
                subs[index] = subClone
                setSubtitle(subs)
            }
        },
        [hasSub, copySubs, setSubtitle, formatSub],
    )

    const mergeSub = useCallback(
        (sub) => {
            const index = hasSub(sub)
            if (index < 0) return
            const subs = copySubs()
            const next = subs[index + 1]
            if (!next) return
            const merge = newSub({
                start: sub.start,
                end: next.end,
                text: sub.text.trim() + "\n" + next.text.trim(),
            })
            subs[index] = merge
            subs.splice(index + 1, 1)
            setSubtitle(subs)
        },
        [hasSub, copySubs, setSubtitle, newSub],
    )

    const splitSub = useCallback(
        (sub, start) => {
            const index = hasSub(sub)
            if (index < 0 || !sub.text || !start) return
            const subs = copySubs()
            const text1 = sub.text.slice(0, start).trim()
            const text2 = sub.text.slice(start).trim()
            if (!text1 || !text2) return
            const splitDuration = (sub.duration * (start / sub.text.length)).toFixed(3)
            if (splitDuration < 0.2 || sub.duration - splitDuration < 0.2) return
            subs.splice(index, 1)
            const middleTime = DT.d2t(sub.startTime + parseFloat(splitDuration))
            subs.splice(
                index,
                0,
                newSub({
                    start: sub.start,
                    end: middleTime,
                    text: text1,
                }),
            )
            subs.splice(
                index + 1,
                0,
                newSub({
                    start: middleTime,
                    end: sub.end,
                    text: text2,
                }),
            )
            setSubtitle(subs)
        },
        [hasSub, copySubs, setSubtitle, newSub],
    )

    const onKeyDown = useCallback(
        (event) => {
            const keyCode = getKeyCode(event)
            switch (keyCode) {
                case 32:
                    event.preventDefault()
                    if (player) {
                        if (playing) {
                            player.pause()
                        } else {
                            player.play()
                        }
                    }
                    break
                case 90:
                    event.preventDefault()
                    if (event.metaKey) {
                        undoSubs()
                    }
                    break
                default:
                    break
            }
        },
        [player, playing, undoSubs],
    )

    const toggleFullscreen = useCallback(() => {
        if (document.fullscreenElement === editor.current) {
            document.exitFullscreen()
        } else if (editor.current.requestFullscreen) {
            editor.current.requestFullscreen();
        }
    }, [editor]);

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown)
        return () => window.removeEventListener("keydown", onKeyDown)
    }, [onKeyDown])

    useMemo(() => {
        const currentIndex = subtitle.findIndex((item) => item.startTime <= currentTime && item.endTime > currentTime)
        setCurrentIndex(currentIndex)
    }, [currentTime, subtitle])

    useEffect(() => {
        const text = sub2srt(subtitle)
        setLatestSrt(text)
    }, [setLatestSrt, subtitle])

    useEffect(() => {
        const fetchSubtitle = () => {
            onSubtitleChange(srtFile)
        }
        removeSub()
        fetchSubtitle()
    }, [])

    const props = {
        player,
        setPlayer,
        subtitle,
        setSubtitle,
        waveform,
        setWaveform,
        currentTime,
        setCurrentTime,
        goForward,
        setGoForward,
        goRewind,
        setGoRewind,
        duration,
        setDuration,
        currentIndex,
        setCurrentIndex,
        playing,
        setPlaying,
        language,
        setLanguage,
        loading,
        setLoading,
        setProcessing,
        subtitleHistory,
        mediaUrl,
        audioUrl,
        newSub,
        hasSub,
        checkSub,
        removeSub,
        addSub,
        undoSubs,
        clearSubs,
        updateSub,
        formatSub,
        mergeSub,
        splitSub,
    }

    return (
        <div className="SUBPLAYER">
            <div ref={editor} className="editor">
                <ToastContainer/>
                <div className="main">
                    <Player {...props}/>
                    <Subtitles {...props}/>
                    {/* <Tool {...props}/> */}
                </div>
                <Footer {...props}/>
                <button className="save" onClick={save}><img src={saveIcon} alt={t("Save subtitles")}/></button>
                <button className="fullscreen" onClick={toggleFullscreen}><img src={fullscreenIcon} alt=""/><img src={fullscreenExitIcon} alt=""/></button>
            </div>
            {loading ? <Loading loading={loading}/> : null}
            {processing > 0 && processing < 100 ? <ProgressBar processing={processing}/> : null}
        </div>
    )
}
