import {useState, useReducer, useEffect} from "react"
import {connect} from "react-redux"
import {globalSelector} from "../redux/selectors/globalSelector"
import axios from "axios"
import {API_PROJECTS} from "../constants/apiUrls"
import {REFRESH_COGNITO_TOKEN} from "../redux/actions/sessionActions"
import currentAuthSession from "../aws/cognito/currentAuthSession"
import ConfirmationModal from "../components/confirmation-modal/ConfirmationModal"
import ProjectsModalForm from "../sections/projects-modal/ProjectsModal"
import HomeHeader from "../sections/home-header/HomeHeader"
import HomeContent from "../sections/home-content/HomeContent"

function Home({projectsState, sessionState}) {
    const [isRender, forceUpdate] = useReducer(x => x + 1 + 1, 0)
    const [deleteConfirmation, setDeleteConfirmation] = useState(false)

    useEffect(() => {
        if (deleteConfirmation) {
            currentAuthSession().then((res) => {
                REFRESH_COGNITO_TOKEN(res.idToken.toString(), res.idToken.payload.id_user, res.idToken.payload.id_team ? res.idToken.payload.id_team : undefined, res.idToken.payload.role)
            })
            const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
            axios.delete(`${API_PROJECTS}/${projectsState.isConfirmationModal.id}`, headersRequest)
            .then(() => {
                forceUpdate()
            })
            .catch((err) => {
                console.log("error :", err)
            })
        }
    }, [deleteConfirmation])

    return (
        <>
        <div className="VIEW HOME">
            {projectsState.isConfirmationModal.modal && <ConfirmationModal type={"projects"} setDeleteConfirmation={setDeleteConfirmation}/>}
            {projectsState.isModal && <ProjectsModalForm forceUpdate={forceUpdate}/>}
            <HomeHeader/>
            <span className="line"></span>
            <HomeContent isRender={isRender}/>
        </div>
        </>
    )
}

export default connect(globalSelector)(Home)