import "../src/sass/main.scss"
import "react-toastify/dist/ReactToastify.css"
import {useEffect} from "react"
import {Hub} from "aws-amplify/utils"
import {toast} from "react-toastify"
import {useTranslation} from "react-i18next"
import {ToastContainer} from "react-toastify"
import AllRoutes from "./views/AllRoutes"

export default function App() {
    const {t} = useTranslation()

    useEffect(() => {
        Hub.listen("auth", ({payload}) => {
            switch (payload.event) {
                case "signedIn":
                    console.log("user have been signedIn successfully.")
                    break
                case "signedOut":
                    console.log("user have been signedOut successfully.")
                    break
                case "tokenRefresh":
                    console.log("auth tokens have been refreshed.")
                    break
                case "tokenRefresh_failure":
                    console.log("failure while refreshing auth tokens.")
                    break
                case "signInWithRedirect":
                    console.log("signInWithRedirect API has successfully been resolved.")
                    break
                case "signInWithRedirect_failure":
                    console.log("failure while trying to resolve signInWithRedirect API.")
                    if (payload.data.error.stack.slice(0, 90) === "OAuthSignInException: PreTokenGeneration failed with error No Associated User in Database.") {
                        toast.error(t("User not recognized by Neurowide-Studio."))
                    }
                    break
                case "customOAuthState":
                    console.log("custom state returned from CognitoHosted UI")
                    break
                default:
                    break
            }
        })
    })

    return (
        <>
        <ToastContainer/>
        <AllRoutes/>
        </>
    )
}