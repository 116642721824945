import {signIn, confirmSignIn, fetchAuthSession} from "aws-amplify/auth"

export default async function firstLogin({email, oldPassword, newPassword}) {
    let {isSignedIn, nextStep} = await signIn({username: email, password: oldPassword})
    switch (nextStep.signInStep) {
        // case "CONFIRM_SIGN_IN_WITH_TOTP_CODE":
        case "CONFIRM_SIGN_IN_WITH_SMS_CODE":
            // nextStep will contain codeDeliveryDetails when step is CONFIRM_SIGN_IN_WITH_SMS_CODE
            await confirmSignIn({challengeResponse: "code"})
            break
        case "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE":
            const {additionalInfo} = nextStep
            await confirmSignIn({challengeResponse: "answer"})
            break
        case "CONTINUE_SIGN_IN_WITH_TOTP_SETUP":
            // Note that calling setupTOTP is unnecessary in this scenario
            // totpSetupDetails are already returned with nextStep
            const {totpSetupDetails} = nextStep
            const appName = "my_app_name"
            const setupUri = totpSetupDetails.getSetupUri(appName)
            // Open setupUri with an authenticator APP to retrieve an OTP code
            // Retrieve code from authenticator App
            await confirmSignIn({challengeResponse: "code"})
            break
        case "CONTINUE_SIGN_IN_WITH_MFA_SELECTION":
            const mfaType = "SMS" // or TOTP
            await confirmSignIn({challengeResponse: mfaType})
            // if confirmSignIn was called with 'SMS', then the next
            // step will be 'CONFIRM_SIGN_IN_WITH_SMS_CODE'.
            // However if 'TOTP' was used as input then 'CONFIRM_SIGN_IN_WITH_TOTP_CODE'
            // will be returned.
            const otpCode = "123456" // code retrieved from authenticator App or cellphone
            await confirmSignIn({challengeResponse: otpCode})
            break
        case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED":
            const {missingAttributes} = nextStep
            await confirmSignIn({challengeResponse: newPassword})
            break
        case "DONE":
            break
        default:
            break
    }
    try {
        const {accessToken, idToken} = (await fetchAuthSession()).tokens ?? {}
        return {accessToken, idToken}
    } catch (error) {
        console.log("error currentAuthSession in", error)
    }
}