import axios from "axios"
import useSWR from "swr"
import {API_LEXICONS} from "../constants/apiUrls"

export default function useLexicons(tokenID) {

    const fetcher = (args) =>
        axios.get(args.url, {headers: {Authorization: `Bearer ${args.token}`}})
        .then((res) => {
            return res.data
        })

    const {data, mutate, error} = useSWR({url: API_LEXICONS, token: tokenID}, fetcher)

    return {
        lexicons: data,
        mutateLexicons: mutate,
        isLoadingLexicons: !error && !data,
        isErrorLexicons: error
    }
}