import {useEffect} from "react"
import {connect} from "react-redux"
import {localSelector} from "../../redux/selectors/localSelector"
import spinnerIcon from "../../assets/icon/spinner.svg"
import {useTranslation} from "react-i18next"

function LoadingModal({localState, display, trie}) {
    const {t} = useTranslation()
    const steps = {
        "upload": "Media being uploaded",
        "lexicons": "Media being transcribed",
        "lexiconsDone": "Media being transcribed",
        "transcription": "Media being transcribed",
        "translation": "Media being translated",
        "render": "Project being render",
        "archive": "Project being render"
    }

    useEffect(() => {
        const bodyNode = document.getElementsByTagName("body")[0]
        if (display) {
            bodyNode.style.overflow = "hidden"
        } else {
            bodyNode.style.overflow = "scroll"
        }
    }, [display])

    return (
        <>
        <div className={`loading-modal ${display && "force-display"}`} style={{width: localState.minNavigation && "93.5%"}}>
            <div className="content" style={{left: localState.minNavigation && "50%"}}>
                <img className="icon" src={spinnerIcon} alt=""/>
                <h3>{trie && t(steps[trie.step])}</h3>
            </div>
        </div>
        </>
    )
}

export default connect(localSelector)(LoadingModal)