import {connect} from "react-redux"
import {globalSelector} from "../../redux/selectors/globalSelector"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import axios from "axios"
import {API_PROJECTS} from "../../constants/apiUrls"
import {useTranslation} from "react-i18next"
import {IS_MODAL} from "../../redux/actions/lexiconsActions"
import SelectLexiconsBtn from "../../components/select-lexicons-btn/SelectLexiconsBtn"
import SkipLexiconBtn from "../../components/skip-lexicon-btn/SkipLexiconBtn"
import lexiconNavIcon from "../../assets/icon/lexicons-nav.svg"
import useTeamLexiconID from "../../swrCustomHooks/useTeamLexiconID"
import useTeamLexicon from "../../swrCustomHooks/useTeamLexicon"
import LoadingComponent from "../../components/loading-component/LoadingComponent"
import LexiconAccordion from "../../components/lexicon-accordion/LexiconAccordion"
import {toast} from "react-toastify"

function WorkflowLexicons01({sessionState, projectsState, REFRESH_COGNITO_TOKEN, setIsPulling}) {
    const {t} = useTranslation()
    const {teamLexiconID, isLoadingTeamLexiconID, isErrorTeamLexiconID} = useTeamLexiconID(sessionState.idToken, sessionState.idTeam)
    const {teamLexicon, isLoadingTeamLexicon, isErrorTeamLexicon} = useTeamLexicon(sessionState.idToken, teamLexiconID)

    const skipLexiconsStep = () => {
        const bodyRequest = {
            lexicon_status: "skip"
        }
        currentAuthSession().then((res) => {
            REFRESH_COGNITO_TOKEN(res.idToken.toString(), res.idToken.payload.id_user, res.idToken.payload.id_team ? res.idToken.payload.id_team : undefined, res.idToken.payload.role)
        })
        const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
        axios.put(`${API_PROJECTS}/${projectsState.projectID}`, bodyRequest, headersRequest)
        .then(() => {
            axios.post(`${API_PROJECTS}/${projectsState.projectID}/tries`, {step: "transcription"}, headersRequest)
            .then((res) => {
                if (res.data.message === `maximun number of tries for "transcription" has been reached or exceeded`) {
                    toast.warning(t("The maximum number of transcriptions has been reached or exceeded!"))
                }
                setIsPulling(true)
            })
            .catch((err) => {
                console.log("error :", err)
            })
        })
        .catch((err) => {
            console.log("error :", err)
        })
    }

    if (isErrorTeamLexiconID || isErrorTeamLexicon || isLoadingTeamLexiconID || isLoadingTeamLexicon || teamLexiconID.message || teamLexicon.message) return <LoadingComponent/>
    return (
        <>
        <div className="SECTION WORKFLOW-LEXICON-01">
            <div className="header">
                <LexiconAccordion lexiconName={t("Team Lexicon")} lexiconItems={teamLexicon} lexiconID={teamLexiconID} isDisable={true}/>
            </div>
            <div className="content">
                <div className="visual">
                    <img src={lexiconNavIcon} alt=""/>
                </div>
                <h2>{t("CHOICE OF LEXICONS")}</h2>
                <p>{t("Select an additionnal lexicon for your project (optional).")}</p>
                <div className="actions">
                    <SelectLexiconsBtn/>
                    <SkipLexiconBtn skipLexiconsStep={skipLexiconsStep}/>
                </div>
            </div>
        </div>
        </>
    )
}

export default connect(globalSelector, {IS_MODAL, REFRESH_COGNITO_TOKEN})(WorkflowLexicons01)