import axios from "axios"
import useSWR from "swr"
import {API_PROJECTS} from "../constants/apiUrls"

export default function useProjectLexicon(tokenID, projectID) {

    const fetcher = (args) =>
        axios.get(args.url, {headers: {Authorization: `Bearer ${args.token}`}})
        .then((res) => {
            for (let lexicon of res.data) {
                if (lexicon.type === "project") {
                    return lexicon
                }
            }
        })

    const {data, mutate, error} = useSWR({url: `${API_PROJECTS}/${projectID}/lexicons`, token: tokenID}, fetcher)

    return {
        projectLexicon: data,
        mutateProjectLexicon: mutate,
        isLoadingProjectLexicon: !error && !data,
        isErrorProjectLexicon: error
    }
}