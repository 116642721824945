import {useReducer, useState, useEffect} from "react"
import {connect} from "react-redux"
import {globalSelector} from "../redux/selectors/globalSelector"
import TeamManagementHeader from "../sections/team-management-header/TeamManagementHeader"
import TeamManagementContent from "../sections/team-management-content/TeamManagementContent"
import ConfirmationModal from "../components/confirmation-modal/ConfirmationModal"
import axios from "axios"
import {API_TEAMS} from "../constants/apiUrls"
import {REFRESH_COGNITO_TOKEN} from "../redux/actions/sessionActions"
import currentAuthSession from "../aws/cognito/currentAuthSession"
import RequireIsManager from "../tools/RequireIsManager"

function TeamManagement({sessionState, teamsState, REFRESH_COGNITO_TOKEN}) {
    const [isRender, forceUpdate] = useReducer(x => x + 1 + 1, 0)
    const [deleteConfirmation, setDeleteConfirmation] = useState(false)

    useEffect(() => {
        if (deleteConfirmation) {
            currentAuthSession().then((res) => {
                REFRESH_COGNITO_TOKEN(res.idToken.toString(), res.idToken.payload.id_user, res.idToken.payload.id_team ? res.idToken.payload.id_team : undefined, res.idToken.payload.role)
            })
            const paramsRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}, data: [teamsState.isConfirmationModal.id]}
            axios.delete(`${API_TEAMS}/${sessionState.idTeam}/users`, paramsRequest)
            .then(() => {
                forceUpdate()
                window.location.reload()
            })
            .catch((err) => {
                console.log("error :", err)
            })
        }
    }, [deleteConfirmation])

    return (
        <>
        <RequireIsManager role={sessionState.role} redirect={true}>
            <div className="VIEW TEAM-MANAGEMENT">
                {teamsState.isConfirmationModal.modal && <ConfirmationModal type={"teams"} setDeleteConfirmation={setDeleteConfirmation}/>}
                <TeamManagementHeader/>
                <span className="line"></span>
                <TeamManagementContent isRender={isRender} forceUpdate={forceUpdate}/>
            </div>
        </RequireIsManager>
        </>
    )
}

export default connect(globalSelector, {REFRESH_COGNITO_TOKEN})(TeamManagement)