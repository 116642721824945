import axios from "axios"
import useSWR from "swr"
import {API_TEAMS} from "../constants/apiUrls"

export default function useTeam(idTeam, tokenID) {

    const fetcher = (args) =>
        axios.get(args.url, {headers: {Authorization: `Bearer ${args.token}`}})
        .then((res) => {
            if (res.data.team.max_duration) {
                return res.data
            } else {
                return new Error("fail")
            }
        })
        .catch((err) => {
            console.log("error :", err)
        })

    const {data, mutate, error} = useSWR({url: `${API_TEAMS}/${idTeam}`, token: tokenID}, fetcher)

    return {
        team: data,
        mutateTeam: mutate,
        isLoadingTeam: !error && !data,
        isErrorTeam: error
    }
}