import {connect} from "react-redux"
import {sessionSelector} from "../redux/selectors/sessionSelector"
import {REFRESH_COGNITO_TOKEN} from "../redux/actions/sessionActions"
import currentAuthSession from "../aws/cognito/currentAuthSession"
import {useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import axios from "axios"
import {API_ME} from "../constants/apiUrls"
import useUser from "../swrCustomHooks/useUser"
import LoadingComponent from "../components/loading-component/LoadingComponent"
import noEncryptionIcon from "../assets/icon/no-encryption.svg"

function ProfileForm({sessionState, REFRESH_COGNITO_TOKEN}) {
    const {t} = useTranslation()
    const {user, mutateUser, isLoadingUser, isErrorUser} = useUser(sessionState.idUser, sessionState.idToken)
    const {register, handleSubmit, formState: {errors}} = useForm()

    const updateProfileRequest = (data) => {
        currentAuthSession().then((res) => {
            REFRESH_COGNITO_TOKEN(res.idToken.toString(), res.idToken.payload.id_user, res.idToken.payload.id_team ? res.idToken.payload.id_team : undefined, res.idToken.payload.role)
        })
        const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
        if (data.firstname === "") {
            data.firstname = user.firstname
        }
        if (data.lastname === "") {
            data.lastname = user.lastname
        }
        axios.put(API_ME, data, headersRequest)
        .then(() => {
            mutateUser()
            window.location.reload()
        })
        .catch((err) => {
            console.log("error :", err)
        })
    }

    if (isErrorUser || isLoadingUser || user.message) return <LoadingComponent/>
    return (
        <>
        <form className="profile-form" onSubmit={handleSubmit(updateProfileRequest)}>
            <div className="field firstname">
                <label for="">{t("Firstname")}</label>
                <input type="text" id="firstname" name="firstname" placeholder={user.firstname} {...register("firstname", {pattern: /^[a-zA-Z0-9!@#$&()`.+,/"-\s\-_ ’'‘ÆÐƎƏƐƔĲŊŒẞÞǷȜæðǝəɛɣĳŋœĸſßþƿȝĄƁÇĐƊĘĦĮƘŁØƠŞȘŢȚŦŲƯY̨Ƴąɓçđɗęħįƙłøơşșţțŧųưy̨ƴÁÀÂÄǍĂĀÃÅǺĄÆǼǢƁĆĊĈČÇĎḌĐƊÐÉÈĖÊËĚĔĒĘẸƎƏƐĠĜǦĞĢƔáàâäǎăāãåǻąæǽǣɓćċĉčçďḍđɗðéèėêëěĕēęẹǝəɛġĝǧğģɣĤḤĦIÍÌİÎÏǏĬĪĨĮỊĲĴĶƘĹĻŁĽĿʼNŃN̈ŇÑŅŊÓÒÔÖǑŎŌÕŐỌØǾƠŒĥḥħıíìiîïǐĭīĩįịĳĵķƙĸĺļłľŀŉńn̈ňñņŋóòôöǒŏōõőọøǿơœŔŘŖŚŜŠŞȘṢẞŤŢṬŦÞÚÙÛÜǓŬŪŨŰŮŲỤƯẂẀŴẄǷÝỲŶŸȲỸƳŹŻŽẒŕřŗſśŝšşșṣßťţṭŧþúùûüǔŭūũűůųụưẃẁŵẅƿýỳŷÿȳỹƴźżžẓ]{2,54}$/})}/>
            </div>
            <div className="field lastname">
                <label for="">{t("Lastname")}</label>
                <input type="text" id="lastname" name="lastname" placeholder={user.lastname} {...register("lastname", {pattern: /^[a-zA-Z0-9!@#$&()`.+,/"-\s\-_ ’'‘ÆÐƎƏƐƔĲŊŒẞÞǷȜæðǝəɛɣĳŋœĸſßþƿȝĄƁÇĐƊĘĦĮƘŁØƠŞȘŢȚŦŲƯY̨Ƴąɓçđɗęħįƙłøơşșţțŧųưy̨ƴÁÀÂÄǍĂĀÃÅǺĄÆǼǢƁĆĊĈČÇĎḌĐƊÐÉÈĖÊËĚĔĒĘẸƎƏƐĠĜǦĞĢƔáàâäǎăāãåǻąæǽǣɓćċĉčçďḍđɗðéèėêëěĕēęẹǝəɛġĝǧğģɣĤḤĦIÍÌİÎÏǏĬĪĨĮỊĲĴĶƘĹĻŁĽĿʼNŃN̈ŇÑŅŊÓÒÔÖǑŎŌÕŐỌØǾƠŒĥḥħıíìiîïǐĭīĩįịĳĵķƙĸĺļłľŀŉńn̈ňñņŋóòôöǒŏōõőọøǿơœŔŘŖŚŜŠŞȘṢẞŤŢṬŦÞÚÙÛÜǓŬŪŨŰŮŲỤƯẂẀŴẄǷÝỲŶŸȲỸƳŹŻŽẒŕřŗſśŝšşșṣßťţṭŧþúùûüǔŭūũűůųụưẃẁŵẅƿýỳŷÿȳỹƴźżžẓ]{2,54}$/})}/>
            </div>
            <div className="field mail">
                <label for="">Email</label>
                <input type="mail" placeholder={user.email} disabled/>
                <img src={noEncryptionIcon} alt=""/>
            </div>
            <button>{t("Update")}</button>
        </form>
        </>
    )
}

export default connect(sessionSelector, {REFRESH_COGNITO_TOKEN})(ProfileForm)