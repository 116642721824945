import {useState, useEffect} from "react"
import {connect} from "react-redux"
import {sessionSelector} from "../../redux/selectors/sessionSelector"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import {useTranslation} from "react-i18next"
import {useForm} from "react-hook-form"
import useTeamUsers from "../../swrCustomHooks/useTeamUsers"
import useUser from "../../swrCustomHooks/useUser"
import axios from "axios"
import {API_TEAMS} from "../../constants/apiUrls"
import Select from "react-select"
import LoadingComponent from "../../components/loading-component/LoadingComponent"
import {useNavigate} from "react-router-dom"

function UpdateTeamForm({sessionState, team, REFRESH_COGNITO_TOKEN, forceUpdate}) {
    const {t} = useTranslation([])
    const navigate = useNavigate()
    const {teamUsers, isLoadingTeamUsers, isErrorTeamUsers} = useTeamUsers(team.id, sessionState.idToken)
    const {user, isLoadingUser, isErrorUser} = useUser(team.id_admin, sessionState.idToken)
    const [selectValue, setSelectValue] = useState(team.id_admin)
    const {register, handleSubmit, formState: {errors}} = useForm({defaultValues: {
        name: team.name,
        maxUsers: team.max_users,
        nbCredits: team.nb_credits,
        maxTranscription: team.max_transcription_per_project,
        maxTraslation: team.max_translation_per_project,
        maxDuration: team.max_duration,
        licenceStartDate: team.licence_start_date.split("T")[0],
        licenceEndDate: team.licence_end_date.split("T")[0],
    }})
    const options = []

    try {
        for (let teamUser of teamUsers) {
            options.push({value: teamUser.id, label: teamUser.email})
        }
    } catch {}

    const handleRequest = async (data) => {
        const bodyRequest = {
            name: data.name,
            licence_start_date: data.licenceStartDate,
            licence_end_date: data.licenceEndDate,
            max_users: parseInt(data.maxUsers),
            nb_credits: parseInt(data.nbCredits),
            max_transcription_per_project: parseInt(data.maxTranscription),
            max_translation_per_project: parseInt(data.maxTraslation),
            max_duration: parseInt(data.maxDuration),
            id_admin: selectValue.value,
        }
        currentAuthSession().then((res) => {
            REFRESH_COGNITO_TOKEN(res.idToken.toString(), res.idToken.payload.id_user, res.idToken.payload.id_team ? res.idToken.payload.id_team : undefined, res.idToken.payload.role)
        })
        const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
        axios.put(`${API_TEAMS}/${team.id}`, bodyRequest, headersRequest)
        .then(() => {
            forceUpdate()
            navigate("/neurowide-dashboard")
        }).catch((err) => {
            console.log("error :", err)
        })
    }

    useEffect(() => {
        currentAuthSession().then((res) => {
            REFRESH_COGNITO_TOKEN(res.idToken.toString(), res.idToken.payload.id_user, res.idToken.payload.id_team ? res.idToken.payload.id_team : undefined, res.idToken.payload.role)
        })
    }, [])


    if (isLoadingTeamUsers || isErrorUser || isErrorTeamUsers || isLoadingUser || teamUsers.message || !user) return <LoadingComponent/>
    try {if (user.message) return <LoadingComponent/>} catch {}
    return (
        <>
        <form className="update-team-form" onSubmit={handleSubmit(handleRequest)}>
            <div className="category">
                <h3>{t("LICENCE")}</h3>
                <div className="field">
                    <label for="">{t("Licence name")}*</label>
                    <input type="text" id="name" name="name" placeholder={t("Licence name")} {...register("name", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">{t("Number of creators")}*</label>
                    <input type="number" id="max-users" name="max-users" placeholder={t("Ex. Project 01")} min="1" {...register("maxUsers", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">{t("Start")}*</label>
                    <input type="date" id="licence-start-date" name="licence-start-date" placeholder="" {...register("licenceStartDate", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">{t("End")}*</label>
                    <input type="date" id="licence-end-date" name="licence-end-date" placeholder="" {...register("licenceEndDate", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">Team manager</label>
                    <Select className="select" options={options} defaultValue={{value: user.id, label: user.email}} onChange={(value) => setSelectValue(value)}/>
                </div>
            </div>
            <div className="category">
                <h3>{t("CHARACTERISTICS")}</h3>
                <div className="field">
                    <label for="">{t("Credits")}*</label>
                    <input type="number" id="nb-credits" name="nb-credits" placeholder="Ex. 10" min="1" {...register("nbCredits", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">{t("Number of transcriptions allowed")}*</label>
                    <input type="number" id="max-transcription" name="max-transcription" placeholder="Ex. 03" min="1" {...register("maxTranscription", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">{t("Number of translations allowed")}*</label>
                    <input type="number" id="max-traslation" name="max-traslation" placeholder="Ex. 03" min="1" {...register("maxTraslation", {required: true})}/>
                </div>
                <div className="field">
                    <label for="">{t("Max audio / video duration")}*</label>
                    <input type="number" id="max-duration" name="max-duration" placeholder="Ex. 15 min" min="1" {...register("maxDuration", {required: true})}/>
                </div>
            </div>
            <button>{t("Update")}</button>
        </form>
        </>
    )
}

export default connect(sessionSelector, {REFRESH_COGNITO_TOKEN})(UpdateTeamForm)