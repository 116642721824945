import {useEffect} from "react"
import {connect} from "react-redux"
import {globalSelector} from "../../redux/selectors/globalSelector"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import useProjects from "../../swrCustomHooks/useProjects"
import LoadingComponent from "../../components/loading-component/LoadingComponent"
import ProjectCard from "../../components/project-card/ProjectCard"

function ProjectsContent({sessionState, projectsState, REFRESH_COGNITO_TOKEN, isRender}) {
    const {projects, mutateProjects, isLoadingProjects, isErrorProjects} = useProjects(sessionState.idToken, projectsState.tab)

    useEffect(() => {
        currentAuthSession().then((res) => {
            REFRESH_COGNITO_TOKEN(res.idToken.toString(), res.idToken.payload.id_user, res.idToken.payload.id_team ? res.idToken.payload.id_team : undefined, res.idToken.payload.role)
        })
        mutateProjects()
    }, [isRender])

    if (isErrorProjects || isLoadingProjects || projects.message) return <LoadingComponent/>
    return (
        <>
        <div className="SECTION PROJECTS-CONTENT">
            {projects.map((project, index) => (
                <ProjectCard key={`project-card-${index}`} project={project} type={projectsState.tab}/>
            ))}
        </div>
        </>
    )
}

export default connect(globalSelector, {REFRESH_COGNITO_TOKEN})(ProjectsContent)