import {useTranslation} from "react-i18next"
import {useState} from "react"
import {connect} from "react-redux"
import {IS_MODAL} from "../../redux/actions/projectsActions"
import ProjectsModalForm from "../../forms/projects/ProjectsModalForm"
import closeIcon from "../../assets/icon/close.svg"
import spinnerIcon from "../../assets/icon/spinner.svg"

function ProjectsModal({IS_MODAL, forceUpdate}) {
    const {t} = useTranslation()
    const [isUpload, setIsUpload] = useState(false)
    return (
        <>
        <div className="projects-modal">
            {isUpload ?
                <>
                <img className="spinner-icon" src={spinnerIcon} alt=""/>
                </>
                :
                <>
                <h2>{t("New project")}</h2>
                <img className="close" src={closeIcon} alt="" onClick={() => IS_MODAL(false)}/>
                <ProjectsModalForm IS_MODAL={IS_MODAL} forceUpdate={forceUpdate} setIsUpload={setIsUpload}/>
                </>
            }
        </div>
        </>
    )
}

export default connect(null, {IS_MODAL})(ProjectsModal)