import {connect} from "react-redux"
import {TEAMS_IS_CONFIRMATION_MODAL} from "../../redux/actions/teamsActions"
import adminIcon from "../../assets/icon/admin.svg"
import trashIcon from "../../assets/icon/trash.svg"
import ProfilePicture from "../profile-picture/ProfilePicture"

function TeamUserCard({user, TEAMS_IS_CONFIRMATION_MODAL, idAdmin}) {

    const deleteUser = () => {
        TEAMS_IS_CONFIRMATION_MODAL({modal: true, id: user.id, action: "delete"})
    }

    return (
        <>
        <div className="team-user-card">
            <ProfilePicture idUser={user.id}/>
            <p>{user.email}</p>
            {user.id === idAdmin && <img className="admin-icon" src={adminIcon} alt=""/>}
            <img className="trash-btn" src={trashIcon} alt="" onClick={deleteUser}/>
        </div>
        </>
    )
}

export default connect(null, {TEAMS_IS_CONFIRMATION_MODAL})(TeamUserCard)