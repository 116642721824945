import {useState, useEffect} from "react"
import {connect} from "react-redux"
import {sessionSelector} from "../../redux/selectors/sessionSelector"
import {REFRESH_COGNITO_TOKEN} from "../../redux/actions/sessionActions"
import currentAuthSession from "../../aws/cognito/currentAuthSession"
import {useTranslation} from "react-i18next"
import axios from "axios"
import {API_LEXICONS} from "../../constants/apiUrls"
import useLexicons from "../../swrCustomHooks/useLexicons"
import LexiconCard from "../../components/lexicon-card/LexiconCard"
import AddLexiconBtn from "../../components/add-lexicon-btn/AddLexiconBtn"
import {TagsInput} from "react-tag-input-component"
import LoadingComponent from "../../components/loading-component/LoadingComponent"
import useTeamLexiconID from "../../swrCustomHooks/useTeamLexiconID"

function LexiconsContent({sessionState, REFRESH_COGNITO_TOKEN, isRender}) {
    const {t} = useTranslation()
    const {teamLexiconID, mutateTeamLexiconID, isLoadingTeamLexiconID, isErrorTeamLexiconID} = useTeamLexiconID(sessionState.idToken, sessionState.idTeam)
    const {lexicons, mutateLexicons, isLoadingLexicons, isErrorLexicons} = useLexicons(sessionState.idToken)
    const [lexiconSelected, setLexiconSelected] = useState()
    const [selected, setSelected] = useState()

    const handleRequest = (data) => {
        setSelected(data)
        currentAuthSession().then((res) => {
            REFRESH_COGNITO_TOKEN(res.idToken.toString(), res.idToken.payload.id_user, res.idToken.payload.id_team ? res.idToken.payload.id_team : undefined, res.idToken.payload.role)
        })
        const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}
        axios.put(`${API_LEXICONS}/${lexiconSelected.id}/items`, data, headersRequest)
        .then((res) => {
            console.log("res :", res)
        })
        .catch((err) => {
            console.log("error :", err)
        })
    }

    useEffect(() => {
        currentAuthSession().then((res) => {
            REFRESH_COGNITO_TOKEN(res.idToken.toString(), res.idToken.payload.id_user, res.idToken.payload.id_team ? res.idToken.payload.id_team : undefined, res.idToken.payload.role)
        })
        setLexiconSelected()
        mutateTeamLexiconID()
        mutateLexicons()
    }, [isRender])

    if (isErrorTeamLexiconID || isErrorLexicons || isLoadingTeamLexiconID || isLoadingLexicons || teamLexiconID.message || lexicons.message) return <LoadingComponent/>
    return (
        <>
        <div className="SECTION LEXICONS-CONTENT">
            <ul className="lexicon-list">
                <AddLexiconBtn/>
                {teamLexiconID && <LexiconCard index={0} lexicon={{id: teamLexiconID, name: t("Team Lexicon")}} setLexiconSelected={setLexiconSelected} setSelected={setSelected} teamLexicon={true}/>}
                {lexicons.map((lexicon, index) => (
                    <LexiconCard key={`team-${index}`} index={index + 1} lexicon={lexicon} setLexiconSelected={setLexiconSelected} setSelected={setSelected}/>
                ))}
            </ul>
            {lexiconSelected === undefined ? "" :
            <>
            <div className="lexicon-edit">
                <h2>{lexiconSelected.name}</h2>
                <ul className="word-list">
                    {(sessionState.role === "admin" || sessionState.role === "team_admin") && (lexiconSelected.id === teamLexiconID) ?
                        <><TagsInput value={selected} onChange={handleRequest} name="word" placeHolder={t("Add a word")}/></>
                    : sessionState.role === "user" && (lexiconSelected.id === teamLexiconID) ?
                        <><TagsInput value={selected} onChange={handleRequest} name="word" disabled={true}/></>
                    :
                        <><TagsInput value={selected} onChange={handleRequest} name="word" placeHolder={t("Add a word")}/></>
                    }
                </ul>
            </div>
            </>}
        </div>
        </>
    )
}

export default connect(sessionSelector, {REFRESH_COGNITO_TOKEN})(LexiconsContent)