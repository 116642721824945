import axios from "axios"
import useSWR from "swr"
import {API_MY_PROJECTS} from "../constants/apiUrls"
import {API_PROJECTS} from "../constants/apiUrls"

export default function useProjects(tokenID, type) {
    const url = type === "my-projects" ? API_MY_PROJECTS : type === "recycle-bin" ? `${API_PROJECTS}?deleted=true` : type === "shared-projects" ? `${API_PROJECTS}?public=true` : API_PROJECTS

    const fetcher = (args) =>
        axios.get(args.url, {headers: {Authorization: `Bearer ${args.token}`}})
        .then((res) => {
            return res.data
        })

    const {data, mutate, error} = useSWR({url: url, token: tokenID}, fetcher)

    return {
        projects: data,
        mutateProjects: mutate,
        isLoadingProjects: !error && !data,
        isErrorProjects: error
    }
}