import {useState, useEffect} from "react"
import {connect} from "react-redux"
import {globalSelector} from "../../redux/selectors/globalSelector"
import axios from "axios"
import {API_PROJECTS} from "../../constants/apiUrls"
import {useTranslation} from "react-i18next"
import {WORKFLOW_IS_CONFIRMATION_MODAL} from "../../redux/actions/worfklowActions"
import {toast} from "react-toastify"

function WorkflowRender({sessionState,
    projectsState,
    project,
    exportConfirmation,
    setExportConfirmation,
    WORKFLOW_IS_CONFIRMATION_MODAL}) {
    const {t} = useTranslation()
    const [videoUrls, setVideoUrls] = useState([])
    const [lang, setLang] = useState("FR")
    const headersRequest = {headers: {Authorization: `Bearer ${sessionState.idToken}`}}

    const getArchives = async () => {
        await axios.get(`${API_PROJECTS}/${projectsState.projectID}/archive.zip`, headersRequest, {maxRedirects: 0})
        .then((res) => {
            var link = document.createElement("a")
            link.href = res.data
            link.click()
            toast.success(t("Your project has been exported!"))
        })
        .catch(() => {
            toast.error(t("An error has occurred"))
        })
    }

    const exportProject = () => {
        WORKFLOW_IS_CONFIRMATION_MODAL({modal: true, id: projectsState.projectID})
    }

    useEffect(() => {
        if (exportConfirmation) {
            getArchives()
        } else {
            axios.get(`${API_PROJECTS}/${projectsState.projectID}/transcription`, headersRequest, {maxRedirects: 0})
            .then(() => {
                axios.get(`${API_PROJECTS}/${projectsState.projectID}/exports/fr`, headersRequest, {maxRedirects: 0})
                .then((res) => {
                    setVideoUrls([res.data])
                })
                .catch((err) => {
                    console.log("error :", err)
                })
            })
            .catch((err) => {
                console.log("error :", err)
            })
            axios.get(`${API_PROJECTS}/${projectsState.projectID}/transcription`, headersRequest, {maxRedirects: 0})
            .then(() => {
                axios.get(`${API_PROJECTS}/${projectsState.projectID}/exports/en`, headersRequest, {maxRedirects: 0})
                .then((res) => {
                    setVideoUrls(videoUrls => [...videoUrls, res.data])
                })
                .catch((err) => {
                    console.log("error :", err)
                })
            })
            .catch((err) => {
                console.log("error :", err)
            })
        }
        setExportConfirmation(false)
    }, [lang, exportConfirmation])

    return (
        <>
        <div className="SECTION WORKFLOW-RENDER">
            <div className="header">
                <div className="header-content">
                    <h3>{t("Language")} :</h3>
                    <select name="lang-code" id="lang-code" onChange={(e) => setLang(e.target.value)} >
                        <option value="FR">{t("French")}</option>
                        <option value="EN">{t("English")}</option>
                    </select>
                </div>
            </div>
            <video controls crossOrigin="anonymous" src={lang === "FR" ? videoUrls[0] : videoUrls[1]} type="video/mp4"></video>
            {project.archive_status === "done" ?
                <>
                <button className="export-enable" onClick={project.finished ? getArchives : exportProject}>{t("Export")}</button>
                </>
                :
                <>
                <button className="export-disable">{t("Export")}</button>
                </>
            }
        </div>
        </>
    )
}

export default connect(globalSelector, {WORKFLOW_IS_CONFIRMATION_MODAL})(WorkflowRender)