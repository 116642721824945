import * as types from "../redux-constants/neurowideDashboardConstants"

const neurowideDashboard = {
    isModal: false,
}

export default function NeurowideDashboardReducer(state=neurowideDashboard, action) {
    switch(action.type) {
        case types.IS_MODAL:
            return {
                isModal: action.payload.isModal,
            }
        default:
            return state
    }
}