import axios from "axios"
import useSWR from "swr"
import {API_URL} from "../constants/apiUrls"

export default function useProjectLexiconItems(tokenID, lexiconID) {

    const fetcher = (args) =>
        axios.get(args.url, {headers: {Authorization: `Bearer ${args.token}`}})
        .then((res) => {
            return res.data
        })

    const {data, mutate, error} = useSWR(lexiconID ? {url: `${API_URL}/lexicons/${lexiconID}/items`, token: tokenID} : null, fetcher)

    return {
        projectLexiconItems: data,
        mutateProjectLexiconItems: mutate,
        isLoadingProjectLexiconItems: !error && !data,
        isErrorProjectLexiconItems: error
    }
}